<div class="page">
  <div class="content-container extended">
    <div class="w-100 position-relative">
      <div class="position-relative">
        <pla-banner-image [carBanner]="activeSwitch === 'buy' ? 'SEARCH_918_DT' : 'SELL_TURBO_DT'"
          [mobileCarBanner]="activeSwitch === 'buy' ? 'SEARCH_918_MB' : 'SELL_TURBO_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'desktop-mobile-bottom'" [useTint]="false">
          <pla-main-nav-chips [defaultConfig]="false" [useSpacing]="false" [rightPadding]="false"></pla-main-nav-chips>
          <div
            class="d-flex align-items-stretch justify-content-start flex-column px-2 py-3 p-md-4 landing-banner-height"
            style="background-color: rgba(13,13,13,0.14);">
            <!-- <pla-snow></pla-snow> -->

            <div class="d-none d-md-flex align-items-stretch justify-content-start flex-column h-100">
              <!-- HEADER TEXT -->
              <div class="text-start w-100 align-self-start justify-content-between">
                <p class="fs-md-45 fs-mb-35 mb-1 lh-1 fw-bold text-white">
                  {{ activeSwitch === 'buy' ? 'SEARCH ANYTHING. FIND EVERYTHING.' : 'LIST. SELL. GET PAID' }}
                </p>
                <p *ngIf="activeSwitch === 'buy'" class="fw-light fs-md-18 fs-mb-15 mt-2 text-white">
                  {{ plateCount | number }} Registrations Available
                </p>

                <p *ngIf="activeSwitch === 'sell'" class="fw-light fs-md-18 fs-mb-15 mt-2 text-white">Sell direct and
                  save £££ on commission and fees</p>

                <div *ngIf="false" class="mt-4 d-block" style="min-width: 150px; max-width: 200px;">
                  <pla-switch-toggle [selectedTabId]="activeSwitch" [tabs]="tabs" [chipColour]="'#ffffff14'"
                    [pillRadius]="2" [chipTextColour]="'#ffffff'" [selectedChipColour]="'#ffffffdb'"
                    [selectedChipTextColour]="'#3A5B64'" [forceSwitch]="forceSwitch"
                    (switchChange)="SwitchChange($event)"></pla-switch-toggle>
                </div>
              </div>
            </div>

            <div class="flex-fill d-flex d-md-none align-items-stretch justify-content-between flex-column">
              <div class="pt-3 text-start w-100 align-self-start justify-content-start">
                <div style="min-height: 110px;" class="d-flex flex-column align-items-end justify-content-center">
                  <ng-container *ngIf="activeSwitch === 'sell'">
                    <p style="letter-spacing: -0.5px !important;"
                      class="fs-md-45 fs-mb-32 mb-2 lh-1 fw-bolder text-white d-block w-100 text-center">LIST. SELL.
                      GET
                      PAID</p>
                    <p class="fs-md-20 fs-mb-17 mb-4 lh-1 fw-normal text-white d-block w-100 text-center">FREE and
                      INSTANT
                      valuation</p>
                  </ng-container>

                  <ng-container *ngIf="activeSwitch === 'buy'">
                    <p class="fs-md-45 fs-mb-30 mb-2 lh-1 fw-bold text-white d-block w-100 text-center">SEARCH
                      ANYTHING.<br />FIND EVERYTHING</p>
                    <p class="fs-md-45 fs-mb-17 mb-4 lh-1 fw-bold text-white d-block w-100 text-center">COMPARE and SAVE
                    </p>
                  </ng-container>
                </div>

                <div *ngIf="false" class="d-block mx-auto" style="min-width: 150px; max-width: 200px;">
                  <div class="border border-1 border-white">
                    <pla-switch-toggle [selectedTabId]="activeSwitch" [tabs]="tabs" [chipColour]="'#ffffff14'"
                      [pillRadius]="2" [chipTextColour]="'#ffffff'" [selectedChipColour]="'#ffffffdb'"
                      [selectedChipTextColour]="'#3A5B64'" [forceSwitch]="forceSwitch"
                      (switchChange)="SwitchChange($event)"></pla-switch-toggle>
                  </div>
                </div>
              </div>


              <div class="w-100 flex-fill d-flex flex-column justify-content-end align-items-stretch px-3">
                <p *ngIf="activeSwitch === 'sell'"
                  class="fw-normal fs-md-18 fs-mb-14 lh-sm my-2 d-block w-100 text-center text-white">
                  Harness the power of the UK's largest registration database for an evidence driven estimate!
                </p>

                <p *ngIf="activeSwitch === 'buy'"
                  class="fw-normal fs-md-18 fs-mb-14 lh-sm my-2 d-block w-100 text-center text-white mb-5">
                  The whole market at your fingertips<br />{{ plateCount | number }} Registrations Available
                </p>
              </div>

            </div>


          </div>
        </pla-banner-image>
      </div>
    </div>
  </div>

  <div>
    <!-- PAGE CONTENT -->
    <div class="">
      <pla-buy-landing *ngIf="activeSwitch === 'buy'"></pla-buy-landing>
      <pla-sell-landing *ngIf="activeSwitch === 'sell'"></pla-sell-landing>

      <div>
        <div class="content-container extended">
          <div class="container-fluid w-100">
            <div class="row px-0">
              <p class="px-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">Plates Sold - Live Feed</p>
            </div>

            <div class="row">
              <pla-price-history-chart></pla-price-history-chart>
            </div>

            <div class="s-spacing"></div>

            <div class="row">
              <!-- FAQ's -->
              <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div class="px-0 px-md-2">
                  <div class="pb-5 pt-5">
                    <p class="text-center text-md-start fs-md-26 fs-mb-22">
                      Frequently Asked Questions
                    </p>
                  </div>
                  <div class="w-100 px-3 px-md-0 pb-5">
                    <pla-faq [faq]="activeSwitch === 'buy' ? buyFaq : sellFaq"></pla-faq>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <pla-featured-blogs>
          <div title>
            <p class="fw-bold mb-2 text-decoration-none d-block text-dark">In Other News</p>
          </div>
        </pla-featured-blogs>
      </div>
    </div>
  </div>
</div>