import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pla-compare-loader',
  templateUrl: './compare-loader.component.html',
  styleUrls: ['./compare-loader.component.scss'],
})
export class CompareLoaderComponent {
  @Input() public almostComplete = new EventEmitter<void>();
  @Output() public complete = new EventEmitter<void>();
  public loadingTexts: string[] = [
    'Verifying registration details...',
    'Checking DVLA records...',
    'Looking up sales history...',
    'Checking prices from multiple sources...',
  ];

  constructor() {}

  LoadComplete(d: any): void {
    this.complete.emit(d);
  }
}
