<div class="page pt-2 pt-md-4">
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <pla-side-nav></pla-side-nav>
            </div>

            <div *ngIf="false" class="bg-light">
                <!-- <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="false"></pla-featured-plates> -->
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <div>
                    <!-- BREADCRUMBS -->
                    <div class="w-100">
                        <div
                            class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                            <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
                            <span>/</span>
                            <a style="white-space: pre; color: #327b8d" class="text-decoration-none d-block"
                                [routerLink]="['/articles']">Resources</a>
                            <span>/</span>
                            <span style="color: #939393; white-space: pre" class="text-decoration-none d-block">Number
                                Plate Types</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div class="px-0 px-md-2">

                    <!-- CONTENT -->
                    <div class="mx-3 mx-md-0 mt-4 mb-4 border-bottom border-2"
                        style="border-color: #3791A8 !important;">
                        <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Types of Number Plates
                        </p>
                    </div>

                    <div class="container-fluid row mx-auto">
                        <div class="col-12 col-md-6 px-0">
                            <div class="pe-0 pe-md-3">
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                    With the increase in traffic on British
                                    roads, it became necessary to easily identify each vehicle in the event of an
                                    accident
                                    or offence. The Motor Car Act of 1903 mandated that all motor vehicles display a
                                    registration number, with the owners details included in the official vehicle
                                    register.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                    In the wake of this announcement, the 2nd Earl Russell was eager to secure the very
                                    first registration. He queued through the night outside the London County Council
                                    offices to obtain the registration number A1 for his Napier. Over the subsequent
                                    century, the registration plate formats have evolved in length, colour, and
                                    structure to
                                    accommodate the growing number of vehicles. Today, there are four types of private
                                    registration formats: Dateless, Suffix, Prefix, and Current.
                                </p>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 px-0">
                            <img src="https://cdn.platex.co.uk/pages/articles/A1_asset.png" class="d-block w-100" />
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">
                        <!-- DATELESS -->

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Dateless Car Registration (1903 - 1963)
                            </p>
                        </div>

                        <div class="mb-4">
                            <img src="https://cdn.platex.co.uk/pages/articles/dateless_format.png"
                                style="max-width: 250px" class="mx-auto d-block w-100" />
                        </div>
                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Named for their lack of a date identifier, these dateless number plates originally
                                featured 1-2 letters identifying the area of issue, followed by a sequence of 1-999
                                serial numbers. In 1932, dateless plates were extended to a three-letter combination. In
                                some rare instances, dateless registrations were reversed, placing the serial number
                                first to extend the lifespan of the series.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Dateless number plates are highly sought after due to their rarity and the potential for
                                fewer characters, making them a favourite choice for initials. Dateless plates have
                                proven to be excellent investments, with many 1/1 registrations and certain name plates
                                commanding prices in the hundreds of thousands. Dateless registrations can be
                                transferred to any age vehicle.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                Use our specialist <a href="/dateless-search" class="text-decoration-underline"
                                    style="color: #3791A8;">dateless registration</a> tool to search thousands of
                                dateless
                                registrations on the market. Filter your results by length, such as 1/1 or 2/1, and
                                letters first or numbers first.
                            </p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">
                        <!-- SUFFIX -->

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Suffix Car Registration (1963-1982)</p>
                        </div>

                        <div class="mb-4">
                            <img src="https://cdn.platex.co.uk/pages/articles/suffix_format.png"
                                style="max-width: 250px" class="mx-auto d-block w-100" />
                        </div>
                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                To address the problem of running out of registrations, the suffix number plates were
                                introduced in 1963. Building on the existing 1932 scheme, a suffix letter was added to a
                                three-letter combination and a sequence number from 1-999. This suffix letter changed
                                annually on 1st January.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                The “A” suffix was introduced in January 1963, with Middlesex issuing the first suffix
                                registration, AHX 1A, in February 1963. Suffix plates not only provided more
                                registration numbers but also allowed buyers to easily identify a vehicle’s age.
                                However, this led to major sales peaks and troughs as buyers waited for the 1st January
                                to purchase newer cars. In 1967, the suffix letter change was moved to 1st August,
                                starting with the “E” suffix.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                Suffix number plates cannot be added to a car that is older than the year of issue.
                            </p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">
                        <!-- PREFIX -->

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Prefix Car Registration (1983-2001)</p>
                        </div>

                        <div class="mb-4">
                            <img src="https://cdn.platex.co.uk/pages/articles/prefix_format.png"
                                style="max-width: 250px" class="mx-auto d-block w-100" />
                        </div>
                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                By 1983, the suffix letter had reached “Y,” prompting another reversal of the sequence.
                                For the new prefix number plates the year indicator now preceded the sequence numbers
                                (1-999) and the three letters. The available range was restricted to A21 AAA through
                                Y999 YYY, with 1-20 of each prefix year reserved for the DVLA Select Registration Sales
                                Scheme.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Launched in 1989, the scheme initially sold just 658 registrations. Over 30 years later,
                                the DVLA now sells over 400,000 private registrations annually. To manage demand for new
                                vehicles, the prefix number plate release dates changed to twice annually in 1998, with
                                the prefix changing on 1st March and 1st September until the “Y” prefix in August 2001.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                Prefix registrations cannot be added to a car that is older than the year of issue.
                            </p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">
                        <!-- CURRENT -->

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Current Car Registration (2001-present)
                            </p>
                        </div>

                        <div class="mb-4">
                            <img src="https://cdn.platex.co.uk/pages/articles/current_format.png"
                                style="max-width: 250px" class="mx-auto d-block w-100" />
                        </div>
                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                The current style number plate system commenced in September 2001 with an entirely new
                                format. All current registrations now contain seven characters, starting with a
                                two-letter area code, followed by a two-number age identifier, and ending with three
                                random letters.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                The new style plate age identifier changes twice annually. If issued between March and
                                August, the two-digit code represents the year itself; if issued between September and
                                February, it represents the year plus 50. Thus, the first new style registrations in
                                September 2001 had the age identifier “51.” This current plate style is expected to last
                                until February 2051.
                            </p>

                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                Current style registrations cannot be added to a car that is older than the year of
                                issue.
                            </p>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div class="mx-3 mx-md-0">
                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">Build Your Own Number Plate</p>
                        </div>

                        <div>
                            <div class="mb-4">
                                <pla-byo-search-page [style]="'current'" [searchDefault]="false"
                                    [inlineSearch]="true"></pla-byo-search-page>
                            </div>

                            <div>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">Using this tool you design your very
                                    own Current style (AA12 ABC), Prefix (A123 ABC) or Suffix (ABC 123A) registration.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">To start;</p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">1. Choose the Registration Style
                                    using the slider bar.</p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">2. Enter the word or initials and
                                    leave the remaining characters with a “?” to be freely allocated. You will notice
                                    that depending on the style there location of the required numbers changes.</p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">Note. Some letters are not available
                                    for
                                    some series; I, O, Q Z are not available for the first letter of Prefix. Only
                                    certain
                                    numbers have been released</p>
                            </div>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-light">
        <div class="content-container extended">
            <div class="blog-content-container position-relative py-3 mb-3 px-1 px-md-5">
                <p class="fw-bold mb-2 text-decoration-none d-block text-dark">In Other News</p>
                <pla-featured-blogs></pla-featured-blogs>
            </div>
        </div>
    </div>
</div>