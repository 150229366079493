<div class="bg-light" *ngIf="activeblogPosts().length > 0">
    <div class="py-4 px-2 px-md-4">
        <div class="w-100 mx-auto" style="max-width: 1300px">
            <!-- TITLE -->
            <div class="ms-2 ms-md-0">
                <ng-content select="[title]"></ng-content>
            </div>

            <div class="w-100">
                <div class="w-100">
                    <!-- SWIPER -->
                    <swiper #swiperRef class="swiper" [config]="swiperConfig">
                        <ng-container *ngFor="let blogPost of activeblogPosts()">
                            <ng-template swiperSlide>
                                <div class="w-100">
                                    <a href="/blogs/{{ blogPost.handle }}"
                                        class="d-flex align-items-stretch justify-content-center gap-2 py-2 text-dark text-decoration-none justify-content-md-start">
                                        <!-- BLOG POST ROW -->
                                        <div class="d-block w-100 mx-0 flex-shrink-0"
                                            style="max-width: 160px; min-width: 100px">
                                            <img src="{{ blogPost.imageUrl | noCache }}" class="d-block w-100 mx-0" />
                                        </div>
                                        <div class="d-flex flex-fill flex-column align-items-start justify-content-between"
                                            style="max-width: 200px">
                                            <p class="mb-0 text-decoration-none text-start w-100 lh-sm
                                            fw-light fs-md-14 fs-mb-12">
                                                {{ blogPost.title }}
                                            </p>
                                            <small class="d-block">{{ blogPost.summary }}...</small>
                                            <small class="text-start w-100 d-block mb-0">
                                                {{ blogPost.created | ordinalDate }}
                                            </small>
                                        </div>
                                    </a>
                                </div>
                            </ng-template>
                        </ng-container>
                    </swiper>
                </div>
                <div>
                    <!-- ARROWS BABY -->
                    <div class="w-100 d-flex align-items-center justify-content-end mt-2">
                        <div class="ms-auto me-0 d-flex align-items-center gap-2">
                            <button mat-flat-button [disabled]="swiperNav['start']"
                                class="turquoise-button swiper-nav swiper-prev px-0">
                                <mat-icon data-nosnippet>chevron_left</mat-icon>
                            </button>

                            <button mat-flat-button [disabled]="swiperNav['end']"
                                class="turquoise-button swiper-nav swiper-next px-0">
                                <mat-icon data-nosnippet>chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>