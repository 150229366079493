import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import BlogPost from 'src/app/models/blogPost';
import { FeatureType } from '../../../app/components/featured-plates/featured-plates.component';
import { BlogService } from '../../services/blog-service';
import { RouteDataService } from 'src/app/services/route-data-service';

@Component({
  selector: 'pla-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss'],
})
export class BlogPageComponent implements OnInit {
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public blogFeatured = undefined;

  public bloghandle: string;
  public blog: BlogPost;

  constructor(
    private routeDataService: RouteDataService,
    private activatedRoute: ActivatedRoute,
    private blogService: BlogService
  ) {
    this.bloghandle = this.activatedRoute.snapshot.params.blogHandle;
    this.blogService.GetPost(this.bloghandle).subscribe((post: BlogPost) => {
      this.routeDataService.setMeta(`PlateX - ${post.title}`, post.summary, post.imageUrl);
      this.blog = post;
    });

    // get featured plates for blog
    this.blogFeatured = new FeatureType('', this.bloghandle);
  }

  ngOnInit(): void { }
}
