<div>
    <!-- <div *ngIf="upcoming">
        <p><strong>Upcoming:</strong> {{upcoming.soldPrice / 100 | currency: 'GBP' }} {{upcoming.registration}} - {{upcoming.soldDate | date:
            'medium'}}</p>
    </div>
    <button (click)="addPoint()">Add Point</button> -->
    <div class="position-relative">
        <div id="chartdiv" style="width: 100%;"></div>
        <div class="position-absolute top-0 bottom-0 start-0" style="right: 33%; background-color: rgba(0,0,0,0.0);">
        </div>
    </div>

    <div class="mt-3">
        <pla-featured-plates [forcedRegistrations]="displayedPlates" [reversed]="true" [showIfEmpty]="false" [flex]="false"
            [showTitle]="false" [showPrice]="false" [priceFontSize]="16" [maxCount]="30" [plateClass]="'flat shaded my-2'"
            [overflow]="true" [mobileTiny]="false" [plateSize]="'ns-s'" [showIndex]="false"></pla-featured-plates>
    </div>
</div>