<div class="container-fluid mt-5">
    <details class="accordion-item">
        <summary class="accordion-button">Media Upload</summary>
        <div class="accordion-body">
            <pla-admin-static-media [maxImages]="5"></pla-admin-static-media>
        </div>
    </details>

    <h2 class="mt-5">Create Blog Post</h2>
    <form class="mt-2 pb-5" (ngSubmit)="submitBlog()">
        <div class="form-group">
            <label for="title">Title</label>
            <input type="text" id="title" class="form-control" [(ngModel)]="blogRequest.title" name="title" required />
        </div>

        <div class="form-group mt-3">
            <label>Paragraphs</label>
            <div *ngFor="let paragraph of blogRequest.paragraphs; let i = index; trackBy: trackByFn">
                <div class="input-group mb-2">
                    <textarea class="form-control" style="resize: vertical;" [(ngModel)]="blogRequest.paragraphs[i]"
                        name="paragraph{{ i }}" rows="5" required>
                    </textarea>
                    <button type="button" class="btn btn-danger" (click)="removeParagraph(i)">
                        <mat-icon class="text-white">delete</mat-icon>
                    </button>
                </div>
            </div>

            <button type="button" class="btn btn-primary" (click)="addParagraph()">
                Add Paragraph
            </button>
        </div>

        <div class="form-group mt-3">
            <label for="imageUrl">Image URL (From Media tab)</label>
            <input type="text" id="imageUrl" class="form-control" [(ngModel)]="blogRequest.imageUrl" name="imageUrl" />

            <div *ngIf="blogRequest.imageUrl" class="mt-2">
                <small class="text-muted">Image preview</small>
                <img src="{{blogRequest.imageUrl}}" class="d-block w-100" style="max-width: 300px" />
            </div>
        </div>

        <div class="form-group mt-3">
            <label for="imageParagraphIndex">Image Paragraph Index (0 means it goes next to the first paragraph, 1 -
                second, 2 - third...)</label>
            <input type="number" id="imageParagraphIndex" class="form-control"
                [(ngModel)]="blogRequest.imageParagraphIndex" name="imageParagraphIndex" min="0" />
        </div>

        <div class="form-group mt-3">
            <label for="author">Author</label>
            <input type="text" id="author" class="form-control" [(ngModel)]="blogRequest.author" name="author" />
        </div>

        <div class="form-group mt-3">
            <label for="created">Created Date</label>
            <input type="datetime-local" id="created" class="form-control" [(ngModel)]="blogRequest.created"
                name="created" />
        </div>

        <div class="form-group mt-3">
            <label for="excerpt">Excerpt (Short description on blog landing page ~ 30 words)
                ({{blogRequest.excerpt != undefined ? blogRequest.excerpt.split('
                ').length : 0}})</label>
            <textarea id="excerpt" class="form-control" [(ngModel)]="blogRequest.excerpt" name="excerpt"
                rows="5"></textarea>
        </div>

        <div class="form-group mt-3">
            <label for="summary">Summary (Short excerpt on blog swiper ~ 10/15 words) ({{blogRequest.summary !=
                undefined ? blogRequest.summary.split('
                ').length : 0}})</label>
            <textarea id="summary" class="form-control" [(ngModel)]="blogRequest.summary" name="summary"
                rows="3"></textarea>
        </div>

        <div class="form-group mt-3">
            <label for="summary">Custom HTML (Shown at the end of the blog)</label>
            <textarea id="summary" class="form-control" [(ngModel)]="blogRequest.html" name="html"
                style="resize: vertical;" rows="5"></textarea>
        </div>

        <div *ngIf="false" class="form-group mt-3">
            <label for="clearImageUrl">Clear Image URL</label>
            <input type="text" id="clearImageUrl" class="form-control" [(ngModel)]="blogRequest.clearImageUrl"
                name="clearImageUrl" />
        </div>

        <button type="submit" class="btn btn-primary mt-4">Submit</button>


        <div class="form-group mt-5">
            <label>Alternatively add JSON here</label>
            <textarea class="form-control" [(ngModel)]="jsonBlogRequest" style="resize: vertical;" rows="5"></textarea>
        </div>
        <button type="button" (click)="JSONChange()" class="btn btn-primary mt-4">Build (Pre loads fields)</button>

        <div class="mt-5 d-none">
            <span class="text-muted">GPT prompt</span>
            <p style="user-select: text;" class="fs-md-14">
                Write me a blog post about SOMETHING for my website, return the response in this JSON format:
                {{'{'}}
                title: '',
                paragraphs: [''],
                excerpt: '',
                summary: '',
                html: ''
                {{'}'}}

                Title is the blog title
                Paragraphs are an array of non HTML text blocks
                Excerpt is a 30 word or less short description of the blog post
                Summary is a 10/15 word or less summary of the blog Post
                html is a custom HTML snippet appended to the end of the blog post like a html table
            </p>
        </div>


    </form>
</div>