<div class="page">
  <div class="content-container px-2">
    <div class="center-content-container">
      <div class="admin-page-container" *ngIf="loaded">
        <div class="mt-4 d-flex align-items-center justify-content-center gap-3">
          <button [ngClass]="{'btn-primary': selectedTab === 'blog', 'btn-outline-primary': selectedTab !== 'blog' }"
            class="btn" (click)="SetTab('blog')">Create Blogs</button>
          <button
            [ngClass]="{'btn-primary': selectedTab === 'blog-config', 'btn-outline-primary': selectedTab !== 'blog-config' }"
            class="btn" (click)="SetTab('blog-config')">Blog Config</button>
          <button
            [ngClass]="{'btn-primary': selectedTab === 'static-media', 'btn-outline-primary': selectedTab !== 'static-media' }"
            class="btn" (click)="SetTab('static-media')">Media</button>
          <button
            [ngClass]="{'btn-primary': selectedTab === 'viewer-config', 'btn-outline-primary': selectedTab !== 'viewer-config' }"
            class="btn" (click)="SetTab('viewer-config')">Plate Viewer</button>
          <button [ngClass]="{'btn-primary': selectedTab === 'gpt', 'btn-outline-primary': selectedTab !== 'gpt' }"
            class="btn" (click)="SetTab('gpt')">GPT Search</button>
          <button
            [ngClass]="{'btn-primary': selectedTab === 'wordbank', 'btn-outline-primary': selectedTab !== 'wordbank' }"
            class="btn" (click)="SetTab('wordbank')">Wordbank</button>
        </div>

        <!-- tabs -->
        <div class="mb-3 mt-5">
          <pla-admin-blog-create *ngIf="selectedTab === 'blog'"></pla-admin-blog-create>
          <pla-admin-blog-config *ngIf="selectedTab === 'blog-config'"></pla-admin-blog-config>
          <pla-admin-static-media *ngIf="selectedTab === 'static-media'"></pla-admin-static-media>
          <pla-admin-plate-viewer-toggle *ngIf="selectedTab === 'viewer-config'"></pla-admin-plate-viewer-toggle>
          <pla-admin-gpt *ngIf="selectedTab === 'gpt'"></pla-admin-gpt>
          <pla-admin-wordbank *ngIf="selectedTab === 'wordbank'"></pla-admin-wordbank>
        </div>
      </div>
    </div>
  </div>
</div>