<div *ngIf="loading" class="mx-auto text-center">
    <pla-loader></pla-loader>
</div>
<div *ngIf="!loading">
    <pla-plate [rawPlate]="registration"></pla-plate>
    <div class="mt-4">
        <div class="d-md-flex justify-content-start align-items-start gap-3 d-block mb-2">
            <button [ngClass]="{ 'platex-button': days == 7, 'platex-border-button': days != 7 }" class="btn"
                (click)="setDays(7)">7 Days</button>
            <button [ngClass]="{ 'platex-button': days == 14, 'platex-border-button': days != 14 }" class="btn"
                (click)="setDays(14)">14 Days</button>
            <button [ngClass]="{ 'platex-button': days == 28, 'platex-border-button': days != 28 }" class="btn"
                (click)="setDays(28)">28 Days</button>
            <button [ngClass]="{ 'platex-button': days == 90, 'platex-border-button': days != 90 }" class="btn"
                (click)="setDays(90)">90 Days</button>
            <button [ngClass]="{ 'platex-button': days == 365, 'platex-border-button': days != 365 }" class="btn"
                (click)="setDays(365)">1 Year</button>
        </div>
        <div class="container-fluid d-block">
            <div class="row">
                <pla-tracking-chart class="col-12 col-md-6 col-lg-4" [icon]="'visibility'" [title]="'Impressions'"
                    [interaction]="'impression'"
                    [description]="'Times registration has been compared or seen in results.'"
                    *ngIf="impressionData.length > 0" [dataPoints]="impressionData" [maxY]="maxY"></pla-tracking-chart>

                <pla-tracking-chart class="col-12 col-md-6 col-lg-4" [icon]="'ads_click'" [title]="'Clicks'"
                    [interaction]="'click'" [description]="'Number of clicks registration has received on PlateX.'"
                    *ngIf="clickData.length > 0" [dataPoints]="clickData" [maxY]="maxY"></pla-tracking-chart>

                <pla-tracking-chart class="col-12 col-md-12 col-lg-4" [icon]="'offline_share'" [title]="'Affiliate'"
                    [interaction]="'affiliate'"
                    [description]="'Number of clicks registration has received to third party.'"
                    *ngIf="affiliateData.length > 0" [dataPoints]="affiliateData" [maxY]="maxY"></pla-tracking-chart>
            </div>
        </div>
        <span class="text-muted w-100 d-block text-center fst-italic">
            Clicks may appear larger than impressions, this can be if
            the registration has been bookmarked or searched directly
        </span>
    </div>

    <div class="mt-4" *ngIf="!loading && trackingData && trackingData.length > 0">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="fw-bolder">Date</th>
                    <th class="fw-bolder">Affiliate Clicks</th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngFor="let metric of orderedTrackingData()">
                    <tr *ngIf="metric.affiliateLinkClicks && metric.affiliateLinkClicks.length > 0">
                        <td>
                            <p class="my-0 fs-md-18">{{ metric.day | relativeTime: true: 2592000}}</p>
                            <p class="my-0">{{metric.affiliateLinkClicks.length}} Clicks</p>
                        </td>
                        <td>
                            <p *ngFor="let af_click of afClick(metric.affiliateLinkClicks); let last = last"
                                class="text-start lh-sm {{ last ? 'my-0' : 'mb-2' }} fs-md-16">
                                {{af_click}}</p>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>