<div class="{{ classes }} position-relative overflow-hidden">
  <div style="padding-bottom: 23px">
    <div class="">
      <div *ngIf="showText" class="rounded-3 overflow-hidden py-3" style="background-color: #f1f4f2 !important">
        <div *ngIf="mainLoader">
          <pla-text-changer [texts]="loadingTexts" [duration]="4"></pla-text-changer>
        </div>
        <div *ngIf="!mainLoader">
          <small class="lh-base fw-normal fs-md-18 fs-mb-16 text-center w-100 d-block"
            style="color: #343434 !important">
            Finalising...
          </small>
        </div>
      </div>

      <div class="w-100">
        <pla-custom-loader [progress]="progress" [barColour]="barColour">
          <div style="transform: translateY(calc(50% + 5px))">
            <img style="width: 54px; height: 40px; object-fit: contain" src="{{ LoaderSVG() }}" />
          </div>
        </pla-custom-loader>
      </div>
    </div>
  </div>
</div>