import {
  Component,
  EventEmitter,
  Input
} from '@angular/core';
import { RegistrationService } from 'src/app/services/registration-service';
declare global {
  interface JQuery {
    marquee(config: any): void;
  }
}

@Component({
  selector: 'pla-search-loader',
  templateUrl: './search-loader.component.html',
  styleUrls: ['./search-loader.component.scss'],
})
export class SearchLoaderComponent {
  public loadingTexts: string[];
  @Input() public almostComplete: EventEmitter<void> = new EventEmitter<void>()

  constructor(private registrationService: RegistrationService) {
    this.registrationService
      .getPlateCount()
      .subscribe((_: { totalCount: number }) => {
        var M_Amount = _.totalCount.toString().substring(0, 2);
        this.loadingTexts = [
          `Searching through ${M_Amount} million registrations.`,
          `Searching through ${M_Amount} million registrations.`,
          `Searching through ${M_Amount} million registrations.`,
          'Scoring results...',
          'Fetching dealers...',
        ]
      });
  }
}
