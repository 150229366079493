<div class="position-relative rounded-pill">
  <div
    class="position-absolute start-0 top-0 bottom-0 overflow-hidden"
    id="bar"
    style="
      height: 5px;
      transform: translateY(-5px);
      border-bottom-left-radius: 0.3rem !important;
    "
    [ngStyle]="{ right: progressPercent() }"
  >
    <div
      class="flex-fill w-100 h-100"
      [ngStyle]="{ background: barColour }"
    ></div>
  </div>
  <div
    id="car"
    class="position-absolute bottom-0"
    [ngStyle]="{ right: progressPercent(true) }"
    style="z-index: 10"
  >
    <ng-content></ng-content>
  </div>
</div>
