<div class="container mt-5">
    <h2>Blog Posts</h2>

    <div *ngIf="loading" class="text-center mt-4">
        <div class="spinner-border" role="status"></div>
        <span class="sr-only">Loading...</span>
    </div>

    <div *ngIf="error" class="alert alert-danger mt-4">
        {{ error }}
    </div>

    <table *ngIf="!loading && !error" class="table table-striped mt-4">
        <thead>
            <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Actions</th>
                <th>Active</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let blog of blogs">
                <td><a target="_blank" href="/blogs/{{blog.handle}}">{{ blog.title }}</a></td>
                <td>{{ blog.created | date: 'short' }}</td>
                <td>
                    <a href="/account/admin?tab=blog&edit={{blog.handle}}"><mat-icon>edit</mat-icon></a>
                    <span (click)="deleteBlog(blog.id, blog.title)"><mat-icon class="text-danger" style="cursor: pointer;">delete</mat-icon></span>
                </td>
                <td>
                    <input type="checkbox" [checked]="blog.active" (change)="toggleActive(blog)" />
                </td>
            </tr>
        </tbody>
    </table>
</div>