<ng-content select="[first]"></ng-content>

<div class="bg-light p-3 text-dark mb-4">
  <p class="fw-bolder fs-md-16">Search</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/buy">Smart Search</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/initials-search">Initials</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/dateless-search">Dateless</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/byo-search">Build-Your-Own</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/new-release/25">New '25' Release</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/new-release/75">Future '75' Release</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/names">Ultimate Names</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/number-1">Number 1's</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/under-200">Under £200</a>
</div>

<ng-content select="[second]"></ng-content>

<div class="bg-light p-3 text-dark mb-4">
  <p class="fw-bolder fs-md-16">Price Comparison</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/articles/how-to-save-money">How to save money</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/compare">Plate Compare</a>
</div>

<ng-content select="[third]"></ng-content>

<div *ngIf="false" class="bg-light p-3 text-dark mb-4">
  <p class="fw-bolder fs-md-16">Sell a Number Plate</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/blog/what-is-my-plate-worth">What is my plate
    worth</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/sell">Free instant evaluation</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/sell">Sell your number plate</a>
</div>

<ng-content select="[fourth]"></ng-content>

<div class="bg-light p-3 text-dark mb-4">
  <p class="fw-bolder fs-md-16">Visualise</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/plate-viewer">Plate Viewer</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/articles/number-plate-types">Number Plate Guide</a>
</div>

<ng-content select="[fifth]"></ng-content>

<div class="bg-light p-3 text-dark mb-4">
  <p class="fw-bolder fs-md-16">Information</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/info">FAQs</a>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/blogs">Number Plate News</a>
  <!-- <a class="fs-6 text-decoration-none text-danger d-block mb-2" href="/blog/number-plate-guide">Number plate guide</a>
  <a class="fs-6 text-decoration-none text-danger d-block mb-2" href="/blog/most-expensive-plates-jan-2024">Most
    expensive plates</a> -->
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="mailto:info@platex.co.uk">Contact us</a>
</div>

<ng-content select="[sixth]"></ng-content>