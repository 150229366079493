import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogCreateRequest } from 'src/app/models/blogCreateRequest';
import BlogPost from 'src/app/models/blogPost';
import { AdminService } from 'src/app/services/admin-service';
import { BlogService } from 'src/app/services/blog-service';

@Component({
  selector: 'pla-admin-blog-create',
  templateUrl: './admin-blog-create.component.html',
  styleUrls: ['./admin-blog-create.component.scss']
})
export class AdminBlogCreateComponent implements OnInit {

  private edit: boolean = false;
  private editId: number;
  public jsonBlogRequest: string;
  public blogRequest: BlogCreateRequest = {
    title: '',
    paragraphs: [''],
    imageUrl: '',
    imageParagraphIndex: 0,
    author: 'PlateX',
    created: '',
    excerpt: '',
    summary: '',
    clearImageUrl: '',
    html: ''
  };

  constructor(private adminService: AdminService, private blogService: BlogService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.jsonBlogRequest = JSON.stringify(this.blogRequest, null, 2)

    this.route.queryParams.subscribe(params => {
      const editId = params['edit'];
      if (editId === undefined || editId === null || editId === '') {
        return;
      }
      this.edit = true;
      this.blogService.GetPost(editId).subscribe((b: BlogPost) => {
        this.editId = b.id;
        this.blogRequest = {
          title: b.title,
          paragraphs: b.paragraphs,
          imageUrl: b.imageUrl,
          imageParagraphIndex: Number.parseInt(b.imageParagraphIndex),
          author: b.author,
          created: b.created,
          excerpt: b.excerpt,
          summary: b.summary,
          clearImageUrl: b.clearImageUrl,
          html: b.html
        }
        this.jsonBlogRequest = JSON.stringify(this.blogRequest, null, 2)
      });
    })
  }

  trackByFn(index: number, item: string): number {
    return index; // Use the index as a unique identifier
}

  addParagraph() {
    this.blogRequest.paragraphs.push('');
  }

  removeParagraph(index: number) {
    this.blogRequest.paragraphs.splice(index, 1);
  }

  JSONChange(): void {
    console.log(this.jsonBlogRequest);
    const temp = JSON.parse(this.jsonBlogRequest) as BlogCreateRequest;
    this.blogRequest.title = temp.title;
    this.blogRequest.paragraphs = temp.paragraphs;
    this.blogRequest.excerpt = temp.excerpt;
    this.blogRequest.summary = temp.summary;
    this.blogRequest.html = temp.html;
    this.jsonBlogRequest = JSON.stringify(this.blogRequest, null, 2);
  }

  submitBlog() {
    // validation
    this.blogRequest.title = this.blogRequest.title.trim();
    this.blogRequest.paragraphs = this.blogRequest.paragraphs.map(p => p.trim());
    this.blogRequest.imageUrl = this.blogRequest.imageUrl.trim();
    this.blogRequest.author = this.blogRequest.author.trim();
    this.blogRequest.summary = this.blogRequest.summary.trim();
    this.blogRequest.excerpt = this.blogRequest.excerpt.trim();

    // requests
    if (this.edit) {
      if (this.editId < 0) { alert('invalid id'); return; }
      this.adminService.UpdateBlog(this.editId, this.blogRequest, (status: boolean) => {
        if (status) {
          this.edit = false;
          this.editId = -1;
          this.blogRequest = {
            title: '',
            paragraphs: [''],
            imageUrl: '',
            imageParagraphIndex: 0,
            author: 'PlateX',
            created: '',
            excerpt: '',
            summary: '',
            clearImageUrl: '',
            html: ''
          };
          alert('Updated blog!')
        } else {
          alert("Failed to create blog")
        }
      });
      return;
    }
    this.adminService.CreateBlog(this.blogRequest, (status: boolean) => {
      if (status) {
        this.blogRequest = {
          title: '',
          paragraphs: [''],
          imageUrl: '',
          imageParagraphIndex: 0,
          author: 'PlateX',
          created: '',
          excerpt: '',
          summary: '',
          clearImageUrl: '',
          html: ''
        };
        alert('Created blog!')
      } else {
        alert("Failed to create blog")
      }
    });
  }

}
