import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HTTPCONSTANTS } from 'src/app/http/http-constants';
import BlogPost from 'src/app/models/blogPost';
import { PlateViewerConfig } from 'src/app/models/plateViewerConfig';
import { AdminService } from 'src/app/services/admin-service';
import { BlogService } from 'src/app/services/blog-service';

@Component({
  selector: 'pla-admin-plate-viewer-toggle',
  templateUrl: './admin-plate-viewer-toggle.component.html',
  styleUrls: ['./admin-plate-viewer-toggle.component.scss']
})
export class AdminPlateViewerToggleComponent implements OnInit {

  configs: PlateViewerConfig[] = [];
  loading: boolean = true;
  error: string | null = null;

  constructor(private http: HttpClient, private adminService: AdminService) { }

  ngOnInit(): void {
    this.fetchBlogs();
  }

  fetchBlogs(): void {
    this.adminService.getAdminImageConfigGroups().subscribe((_configs: PlateViewerConfig[]) => {
      console.log(_configs);
      this.configs = _configs.map((c) => {
        return new PlateViewerConfig(c);
      })
      this.loading = false;
    }, () => {
      this.error = 'Failed to load image config.';
      this.loading = false;
    });
  }

  imageForConfig(config: PlateViewerConfig): string {
    return config.builtConfig.config.imageInfo.path;
  }

  toggleActive(config: PlateViewerConfig): void {
    this.http
      .patch<PlateViewerConfig>(`${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/toggle-viewer-config/${config.id}/update`, null)
      .subscribe(
        (_config: PlateViewerConfig) => {
          const i = this.configs.findIndex(c => c.id == config.id)
          this.configs[i] = new PlateViewerConfig(config); 
        },
        (error) => {
          alert(`Failed to toggle image config status: ${JSON.stringify(error)} / ${error}`);
        }
      );
  }
}
