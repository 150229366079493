import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'pla-custom-loader-box',
  templateUrl: './custom-loader-box.component.html',
  styleUrls: ['./custom-loader-box.component.scss'],
})
export class CustomLoaderBoxComponent implements OnInit {
  @Input() public car: string = 'AVENTADOR_SV';
  @Input() public showText: boolean = true;
  @Input() public classes: string = 'px-0 px-md-4 mb-4';
  @Input() public barColour: string = '#000';
  @Input() public loadingTexts: string[] = [];
  @Input() public almostComplete = new EventEmitter<void>();
  @Output() public complete = new EventEmitter<void>();
  public progress = 0;
  public mainLoader: boolean = true;
  public intervalClock = -1;
  public endClock = -1;

  constructor() { }

  ngOnInit(): void {
    this.Start(66, 0.3);

    this.almostComplete.subscribe(() => {
      console.log('ALMOST COMPLETE TRIGGERED');
      this.mainLoader = false;

      if (this.progress < 90) {
        this.Start(32, 1, () => {
          this.FinalStretch();
        });
      } else {
        this.FinalStretch();
      }
    });
  }

  private FinalStretch(): void {
    this.endClock = window.setInterval(() => {
      if (this.progress >= 100) {
        window.clearInterval(this.endClock);
        setTimeout(() => {
          console.log('LOADER BOX COMPLETE');
          this.mainLoader = true;
          this.complete.emit();
        }, 750);
        return;
      }

      this.progress += 1;
    }, 100);
  }

  public Start(delay: number, increment: number, callback?: () => void): void {
    window.clearInterval(this.intervalClock);
    window.clearInterval(this.endClock);

    this.intervalClock = window.setInterval(() => {
      if (this.progress >= 90) {
        window.clearInterval(this.intervalClock);
        if (callback) callback();
        return;
      }
      this.progress += increment;
    }, delay);
  }

  public LoaderSVG(): SafeHtml {
    const url = CAR_OPTIONS[this.car];
    return url;
  }
}
const CAR_OPTIONS = {
  SLEIGH: `https://cdn.platex.co.uk/site-media/87d0ee06-c5f1-4308-bed7-c8984ed6072b_sleigh_loader.png`,
  XMAS_AVENTADOR_SV: `https://cdn.platex.co.uk/site-media/8203075b-93c0-4509-a3dd-29777e856954_aventador_sv_xmas_loader.png`,
  ORANGE_AVENTADOR_SV: `https://cdn.platex.co.uk/site-media/8cbd477d-68dc-4d06-819d-81f7a244ad3a_SV_E46400_loader.png`,
  AVENTADOR_SV: `https://cdn.platex.co.uk/site-media/6c1f8d56-490d-4e21-9762-d5b3444133e3_aventador_sv_loader.png`,
  ROMA: `https://cdn.platex.co.uk/site-media/e1366f3a-9e55-4541-98c9-6117873d4f1e_roma_loader.png`,
  G_WAGON: `https://cdn.platex.co.uk/site-media/c31772ab-4a7e-4fe0-b730-2ccdf96f1ea5_gwagon_loader.png`,
};
