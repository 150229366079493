import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

export enum StickyLinkType {
  IN_PAGE,
  OUT_OF_PAGE,
  ACTION
}

export class StickyLink {
  constructor(
    public viewName: string,
    public anchor: string,
    public active: boolean,
    public linkType: StickyLinkType = StickyLinkType.IN_PAGE,
    public routeMatch?: string,
    public hidden: boolean = false
  ) {
    if (this.routeMatch === undefined || this.routeMatch === null) this.routeMatch = this.anchor;
  }
}

export class StickyLinkGroup {
  constructor(
    public links: StickyLink[] = [],
  ) { }
}

export class ConfigStyle {
  public borderWidth: number;
  public fontWeight: string;
  public backgroundColour: string;
  public textColour: string;
  public borderColour: string;
}

export class ConfigMeta {
  public sticky: boolean;
  public roundedClass: string;
  public backgroundColour: string;
  public defaultStyle: ConfigStyle;
  public selectedStyle: ConfigStyle;
}

export const DEFAULT_STICKY_CONFIG: ConfigMeta = {
  sticky: true,
  roundedClass: 'rounded-pill',
  backgroundColour: '#fff',
  defaultStyle: {
    fontWeight: 'fw-light',
    borderWidth: 1,
    backgroundColour: '#fff',
    textColour: '#484848',
    borderColour: '#969696'
  },
  selectedStyle: {
    fontWeight: 'fw-light',
    borderWidth: 1,
    backgroundColour: '#0a6077',
    textColour: '#fff',
    borderColour: '#0a6077'
  }
}

export const SECONDARY_STICKY_CONFIG: ConfigMeta = {
  sticky: true,
  roundedClass: 'rounded-pill',
  backgroundColour: '#fff',
  defaultStyle: {
    fontWeight: 'fw-light',
    borderWidth: 1,
    backgroundColour: '#fff',
    textColour: '#2E2E2E',
    borderColour: '#383838',
  },
  selectedStyle: {
    fontWeight: 'fw-light',
    borderWidth: 1,
    backgroundColour: '#E2F9FF',
    textColour: '#2B2B2B',
    borderColour: '#0a6077'
  }
}

export const REDUCED_STICKY_CONFIG: ConfigMeta = {
  sticky: true,
  roundedClass: '',
  backgroundColour: 'transparent',
  defaultStyle: {
    fontWeight: 'fw-light',
    borderWidth: 1,
    backgroundColour: 'transparent',
    textColour: '#2E2E2E',
    borderColour: '#f1f4f2',
  },
  selectedStyle: {
    fontWeight: 'fw-light',
    borderWidth: 1,
    backgroundColour: 'transparent',
    textColour: '#2B2B2B',
    borderColour: '#0a6077'
  }
}

/* SAVED CONFIGS */
export const THICKER_SELECTED_BORDER = { ...SECONDARY_STICKY_CONFIG, selectedStyle: { ...SECONDARY_STICKY_CONFIG.selectedStyle, borderWidth: 2 } };
export const GOOGLE_REDUCED_CHIPS = { ...REDUCED_STICKY_CONFIG, roundedClass: 'border-start-0 border-top-0 border-end-0', defaultStyle: { ...REDUCED_STICKY_CONFIG.defaultStyle, borderWidth: 3 }, selectedStyle: { ...REDUCED_STICKY_CONFIG.selectedStyle, borderWidth: 3, fontWeight: 'fw-bolder' } }
/* SAVED CONFIGS END */

export class StickyConfig {
  constructor(
    public groups: StickyLinkGroup[] = [],
    public reScroll: EventEmitter<void> = new EventEmitter<void>(),
    public metaData: ConfigMeta = DEFAULT_STICKY_CONFIG
  ) { }
}

@Component({
  selector: 'pla-sticky-tabs',
  templateUrl: './sticky-tabs.component.html',
  styleUrls: ['./sticky-tabs.component.scss'],
})
export class StickyTabsComponent implements OnInit, AfterViewInit {
  @Input() public config: StickyConfig = new StickyConfig();
  @Input() public classes: string = 'py-2 border-bottom';
  @Input() public startSpacing: string = 'ps-3 ps-md-1';
  @ViewChild('chipsContainer') chipsContainer: ElementRef;

  public elid: string;
  public groups: StickyLinkGroup[] = [];
  private lastChecked: StickyLink;

  // Dragging related variables
  private isDragging: boolean = false;
  private startX: number = 0;
  private scrollLeft: number = 0;

  constructor(private viewportScroller: ViewportScroller, private router: Router) {
    this.elid = this.makeid(4);
  }

  private makeid(length): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  @HostListener('window:scroll', [])
  onScroll() {
    this.checkVisibility();
  }
  // Mouse down event for dragging
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.isDragging = true;
    this.startX = event.pageX - this.chipsContainer.nativeElement.offsetLeft;
    this.scrollLeft = this.chipsContainer.nativeElement.scrollLeft;
    this.chipsContainer.nativeElement.style.cursor = 'grabbing';

    // Attach mousemove, mouseup, and mouseleave to the document
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mouseleave', this.onMouseLeave);
  }

  // Mouse move event for dragging
  onMouseMove = (event: MouseEvent): void => {
    if (!this.isDragging) return;
    event.preventDefault();
    const x = event.pageX - this.chipsContainer.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 1; // Scroll speed factor (adjustable)
    this.chipsContainer.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  // Mouse up event to stop dragging
  onMouseUp = (): void => {
    if (!this.isDragging) return;
    this.isDragging = false;
    this.chipsContainer.nativeElement.style.cursor = 'grab';

    // Remove the event listeners after mouseup
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mouseleave', this.onMouseLeave);
  }

  // Mouse leave event to stop dragging
  onMouseLeave = (): void => {
    return;
    if (this.isDragging) {
      this.isDragging = false;
      this.chipsContainer.nativeElement.style.cursor = 'grab'; // Reset cursor

      // Remove the event listeners when the mouse leaves
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
      document.removeEventListener('mouseleave', this.onMouseLeave);
    }
  }



  ngOnInit(): void {
    this.groups = this.config.groups;
    this.config.reScroll.subscribe(() => {
      const link = this.GetActiveLink();
      if (link == null) return;
      this.viewportScroller.scrollToAnchor(link.anchor);
      this.scrollChipToView(link);
    });
    this.checkVisibility();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollChipToView(this.GetActiveLink());
    }, 250);
  }

  private GetActiveLink(): StickyLink {
    var groupWithActiveLink = this.groups.find(g => g.links.find((l) => l.active));
    if (groupWithActiveLink === undefined || groupWithActiveLink === null) return null;
    var link = groupWithActiveLink.links.find(l => l.active);
    if (link === undefined || link === null) return null;
    return link;
  }

  private scrollChipToView(link: StickyLink, slow: boolean = false): void {
    if (!link) return;
    if (this.lastChecked && this.lastChecked.anchor == link.anchor) return;
    const linkTag = `${link.anchor.replace('/', '').replace('/', '-')}`;
    $(`#${this.elid}-chips-container`).parent('#sticky-bby').animate({ scrollLeft: $(`#${linkTag}`)[0].offsetLeft - ($(`#${linkTag}`)[0].clientWidth / 2) - 16 }, slow ? 250 : 0);
    this.lastChecked = link;
  }

  private checkVisibility() {
    var topLink: StickyLink = undefined;
    var topPercent = -1;

    const firstLink = this.groups[0].links[0];
    if (firstLink.linkType === StickyLinkType.OUT_OF_PAGE) return;
    this.groups = this.groups.map(g => { g.links.map(l => l.active = false); return g });
    var targetElement = document.getElementById(`${firstLink.anchor}-content`);
    if (targetElement == null) { console.warn(`${firstLink.anchor}`, 'first element not found'); this.groups[0].links[0].active = true; return; }
    const rect = targetElement.getBoundingClientRect();
    if (rect.top > 0) { this.groups[0].links[0].active = true; return; }

    this.groups.forEach(g => {
      g.links.forEach(l => {
        if (l.linkType === StickyLinkType.OUT_OF_PAGE) return;

        var targetElement = document.getElementById(`${l.anchor}-content`);
        if (targetElement == null) {
          return;
        }

        const rect = targetElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const visibleHeight = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0));
        const totalHeight = rect.height;
        const percentageInView = totalHeight > 0 ? (visibleHeight / totalHeight) * 100 : 0;
        const isVisible = percentageInView > 0;
        if (isVisible && (topLink === undefined || topPercent == -1 || percentageInView > topPercent)) {
          topPercent = percentageInView;
          topLink = l;
        }
      })
    });

    if (topLink) {
      this.groups = this.groups.map(g => { g.links.map(l => l.active = topLink.anchor === l.anchor); return g });
    }

    this.scrollChipToView(this.GetActiveLink(), true);
  }

  public ChangeLink(link: StickyLink): void {
    this.groups = this.groups.map(g => {
      g.links = g.links.map((l) => {
        l.active = l.anchor == link.anchor;
        return l;
      });
      return g;
    });

    if (link.linkType === StickyLinkType.IN_PAGE) {
      const anchor = `section-${link.anchor}`;
      this.viewportScroller.scrollToAnchor(anchor);
      this.scrollChipToView(link);
    } else {
      this.router.navigate([link.anchor]);
    }
  }
}
