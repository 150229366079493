<div>
    <!-- File Upload -->
    <div>
        <div>
            <label class="form-label">Upload a new file</label>
            <input type="file" accept="image/png, image/jpeg" (change)="onFileChange($event)"
                class="w-100 form-control" />
        </div>

        <div class="mt-3">
            <label class="d-block form-label">Image Guide</label>
            <ul>
                <li class="my-0">Blogs: square 699px x 699px</li>
                <li class="my-0">Plate Viewer: 1920px x 1200px</li>
                <li class="my-0">Banner DT: 1800px x 600px</li>
                <li class="my-0">Banner MB: 2560px x 1080px</li>
                <li class="my-0">Curated box: 800px x 600px</li>
            </ul>
        </div>
    </div>

    <!-- Images List -->
    <div class="mt-5">
        <label for="formFileLg" class="form-label">View files</label>
        <div class="d-flex align-items-stretch justify-content-start gap-4 flex-wrap">
            <div *ngFor="let img of images; let i = index"
                class="d-flex flex-column gap-1 border rounded-3 overflow-hidden" style="max-width: 200px;">
                <div class="bg-light p-2 flex-fill d-flex align-items-center">
                    <pre>{{img | json}}</pre>
                    <img [src]="img.url" alt="From server" class="d-block w-100" (load)="onImageLoad($event, i)" />
                </div>
                <div class="p-2">
                    <small class="text-center d-block w-100">
                        {{img.width}}px x {{img.height}}px
                    </small>
                    <button (click)="copyToClipboard(img.url)"
                        class="w-100 btn btn-outline-primary d-flex align-items-center justify-content-center gap-3">
                        <span class="d-block my-0">Copy Image Link</span>
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>