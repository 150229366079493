<div class="container mt-5">
    <h2>Viewer Configs</h2>

    <div *ngIf="loading" class="text-center mt-4">
        <div class="spinner-border" role="status"></div>
        <span class="sr-only">Loading...</span>
    </div>

    <div *ngIf="error" class="alert alert-danger mt-4">
        {{ error }}
    </div>

    <table *ngIf="!loading && !error" class="table table-striped mt-4">
        <thead>
            <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Group</th>
                <th>Active</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let config of configs">
                <td>
                    <img src="{{imageForConfig(config)}}" class="w-100 d-block" style="max-width: 100px" />
                </td>
                <td>{{ config.carName }}</td>
                <td>
                    {{config.groupId}}
                </td>
                <td>
                    <input type="checkbox" [checked]="config.active" (change)="toggleActive(config)" />
                </td>
            </tr>
        </tbody>
    </table>
</div>