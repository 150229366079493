import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HTTPCONSTANTS } from 'src/app/http/http-constants';
import BlogPost from 'src/app/models/blogPost';
import { BlogService } from 'src/app/services/blog-service';

@Component({
  selector: 'pla-admin-blog-config',
  templateUrl: './admin-blog-config.component.html',
  styleUrls: ['./admin-blog-config.component.scss']
})
export class AdminBlogConfigComponent implements OnInit {

  blogs: BlogPost[] = [];
  loading: boolean = true;
  error: string | null = null;

  constructor(private http: HttpClient, private blogService: BlogService) { }

  ngOnInit(): void {
    this.fetchBlogs();
  }

  fetchBlogs(): void {
    this.blogService.GetArticles().subscribe((blogs: BlogPost[]) => {
      this.blogs = blogs;
      this.loading = false;
    }, () => {
      this.error = 'Failed to load blogs.';
      this.loading = false;
    });
  }

  deleteBlog(id: string, name: string): void {
    const res = confirm("Are you sure you want to delete this blog? " + name);
    if (!res) return;
    this.http
      .delete(`${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/blog/${id}/delete`)
      .subscribe(
        () => {
          this.fetchBlogs();
        },
        (error) => {
          alert(`Failed to delete blog: ${JSON.stringify(error)} / ${error}`);
        }
      );
  }

  toggleActive(blog: BlogPost): void {
    const newStatus = blog.active ? 'inactive' : 'active';
    this.http
      .get(`${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/blog/${blog.handle}/toggle/${newStatus}`)
      .subscribe(
        () => {
          blog.active = !blog.active;
        },
        (error) => {
          alert(`Failed to toggle blog status: ${JSON.stringify(error)} / ${error}`);
        }
      );
  }
}
