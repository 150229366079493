import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin-service';

@Component({
  selector: 'pla-admin-static-media',
  templateUrl: './admin-static-media.component.html',
  styleUrls: ['./admin-static-media.component.scss']
})
export class AdminStaticMediaComponent implements OnInit, OnDestroy {
  @Input() public maxImages: number = 100000000000000000000;
  images: { url: string, width: number, height: number }[] = [];
  imageFile: File | null = null;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    // Fetch images from API
    this.adminService.getImages().subscribe(
      (images) => {
        this.images = images.slice(0, this.maxImages).map(img => ({
          url: img,
          width: 0,
          height: 0
        }));
      },
      (error) => {
        console.error("Error fetching images", error);
      }
    );
  }

  ngOnDestroy(): void {
    // Cleanup if needed
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.uploadImage(file);
    }
  }

  uploadImage(file: File): void {
    this.adminService.uploadStaticImage(file).subscribe(
      (imageUrl: { url: string }) => {
        this.images = [{ url: imageUrl.url, width: 0, height: 0 }, ...this.images];
      },
      (error) => {
        console.error("Error uploading image", error);
      }
    );
  }

  copyToClipboard(url: string): void {
    navigator.clipboard.writeText(url).then(
      () => {
        console.log('URL copied to clipboard!');
      },
      (err) => {
        console.error('Error copying URL: ', err);
      }
    );
  }

  // This method is triggered when the image is loaded
  onImageLoad(event: any, index: number): void {
    const img = event.target;
    this.images[index].width = img.naturalWidth;
    this.images[index].height = img.naturalHeight;
  }
}
