<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div *ngIf="false" class="bg-light py-3">
        <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right mb-position-sticky start-0 bg-white end-0 my-0 pt-2 pt-lg-0"
        style="top: 55px !important; z-index: 10000">
        <div *ngIf="false">
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/plate-viewer']">Plate Viewer</a>
              <span>{{ lastAlphabetToView }}</span>
            </div>
          </div>
        </div>

        <!-- IMAGE -->
        <div class="w-100 p-2 m-auto">
          <img class="d-block mx-auto viewer-img" style="aspect-ratio: 8/5; max-width: 100%;" *ngIf="selectedImage"
            [src]="selectedImage.image" />
        </div>
      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 pb-0 pt-0 me-0 me-md-2">
        <!-- INPUT -->
        <div>

          <div class="text-center mt-4 px-2">
            <h1 class="px-4 fw-bolder fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb">Plate Viewer</h1>
            <p>
              Visualise your perfect private plate on a range of exceptional
              vehicles with our plate visualiser
            </p>
          </div>

          <div class="px-0 px-md-2 mt-3 w-100 mx-auto" style="max-width: 270px">
            <div class="px-4 px-md-3">
              <div class="w-100 mx-auto search-input">
                <div class="mb-3 mx-auto rounded-2 overflow-hidden plate-background flat shaded position-relative">
                  <div class="mx-auto d-flex align-items-stretch justify-content-between py-2">
                    <div class="bg-transparent w-100 p-1">
                      <pla-new-search-input [defaultInput]="registrationString" [internalRouting]="false"
                        [maxLength]="8" [searchTrigger]="searchTrigger" [searchTexts]="['']" [emitOnInput]="true"
                        (searchEvent)="UpdateInput($event)" [placeholder]="'PLA 73X'"></pla-new-search-input>
                    </div>
                    <button (click)="searchTrigger.emit()" style="max-width: 56px"
                      class="w-100 fs-md-15 fs-mb-15 btn text-dark d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                      <mat-icon data-nosnippet class="">photo_camera</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- IMAGES -->
        <div>
          <div *ngIf="showFeatured">
            <div inViewport class="mb-5 px-3 mx-auto" *ngIf="featuredGroup !== '' && ImagesForFeatured().length > 0"
              style="max-width: 1000px">
              <div class="border-bottom py-2 mb-3">
                <h2 class="display-6 d-block w-100 text-start ms-0 fw-bold">
                  {{ featuredTitle | titlecase }}
                </h2>
              </div>
              <div class="m-auto mt-2 mb-5 container-fluid px-0 w-100">
                <div class="row px-0">
                  <ng-container *ngFor="let config of ImagesForFeatured()">
                    <div class="col-md-4 col-6" style="cursor: pointer" (click)="updateConfig(config)">
                      <img class="d-block w-100" loading="lazy" alt="{{ config.carName }}"
                        [src]="config.builtConfig.config.imageInfo.path" />
                      <p class="m-0 text-center w-100">{{ config.carName }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let letter of alphabet.split('')">
            <div inViewport (inViewportAction)="LetterInView($event, letter)" [inViewportOptions]="{ threshold: 400 }"
              class="mb-5 px-3 mx-auto" *ngIf="ImagesForLetter(letter).length > 0" style="max-width: 1000px">
              <div class="border-bottom py-2 mb-3">
                <h2 class="display-6 d-block w-100 text-start ms-0 fw-bold">
                  {{ letter }}
                </h2>
              </div>
              <div class="m-auto mt-2 mb-5 container-fluid px-0 w-100">
                <div class="row px-0">
                  <ng-container *ngFor="let config of ImagesForLetter(letter)">
                    <div class="col-md-4 col-6" style="cursor: pointer" (click)="updateConfig(config)">
                      <img class="d-block w-100" loading="lazy" alt="{{ config.carName }}"
                        [src]="config.builtConfig.config.imageInfo.path" />
                      <p class="m-0 text-center w-100">{{ config.carName }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image class="w-100" [rawImage]="true" [backgroundImage]="true" [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'">
      <div class="d-flex align-items-stretch justify-content-end new-banner-height">
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient">
          <div class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px">
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>