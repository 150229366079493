import { Pipe, PipeTransform } from '@angular/core';

class Division {
  constructor(public amount: number, public name: Intl.RelativeTimeFormatUnit) { }
}

const DIVISIONS = [
  new Division(60, "seconds"),
  new Division(60, "minutes"),
  new Division(24, "hours"),
  new Division(7, "days"),
  new Division(4.34524, "weeks"),
  new Division(12, "months"),
  new Division(Infinity, "years"),
];

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {

  transform(eventDate: Date, forceToday: boolean = false, maxRelative: number = Infinity, comparisonDate: Date = new Date()): string {
    if (typeof eventDate === 'string') eventDate = new Date(eventDate);
    if (forceToday) {
      if (eventDate.setHours(0, 0, 0, 0) === comparisonDate.setHours(0, 0, 0, 0)) {
        return "Today";
      }
    }

    let duration: number = (new Date(eventDate).getTime() - new Date(comparisonDate).getTime()) / 1000;
    if (Math.abs(duration) >= maxRelative) {
      return eventDate.toLocaleDateString();
    }

    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i];
      if (Math.abs(Math.round(duration)) < division.amount) {
        var res = new Intl.RelativeTimeFormat("en", {
          localeMatcher: "best fit",
          numeric: "auto",
          style: "long",
        }).format(Math.round(duration), division.name);

        res = res.charAt(0).toUpperCase() + res.slice(1); // Capitalise first character
        return res;
      }
      duration /= division.amount;
    }
  }
}
