<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light py-3">
        <pla-featured-plates
          [featureType]="raFeatured"
          [plateSize]="'smaller'"
          [priceFontSize]="16"
          [maxCount]="10"
          [sortBy]="'price'"
          [plateClass]="'flat shaded'"
          [showIndex]="false"
        ></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <pla-banner-image
          [carBanner]="'FACTS_TESTAROSSA_DT'"
          [mobileCarBanner]="'FACTS_TESTAROSSA_MB'"
          [rawImage]="true"
          [backgroundImage]="true"
          [backgroundClass]="'compare-mobile-background'"
        >
          <div class="compare-banner-height">
            <div
              class="d-flex d-md-flex align-items-start justify-content-between flex-column"
            >
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">
                  Number Plate News
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div class="mt-4">
          <!--TEXT CONTENT-->
          <p
            class="px-4 text-start fw-normal fs-md-20 fs-mb-18 mb-0 mx-auto lh-sm hs-mb"
          >
            Hot off the Press Plate News and DVLA Updates
          </p>
          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-3 mx-auto lh-sm">
            Stay updated with the latest trends, news, and legal information
            surrounding private plates. Our blog offers expert guidance on how
            to make the most of your personalised registration, ensuring you
            find a unique plate that suits your style, budget, and car.
          </p>
          <p class="px-4 text-start fs-md-16 fs-mb-14 mb-0 mx-auto lh-sm">
            Whether you're a first-time buyer or a seasoned enthusiast, our
            articles provide insights and advice to help you navigate the world
            of personalised plates. Explore how private plates are used in
            entertainment, from celebrity cars to their appearance in films and
            TV shows, offering a look at their cultural impact.
          </p>
        </div>

        <div class="s-spacing"></div>
      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2"
      >
        <div class="pla_card no-card mobile spacing px-2">
          <!-- CONTENT -->
          <div class="">
            <div *ngIf="activeBlogPosts().length === 0">
              <p class="text-center mt-4">No available blogs</p>
            </div>
            <div *ngIf="activeBlogPosts().length > 0">
              <ng-container *ngFor="let blogPost of activeBlogPosts()">
                <a
                  href="/blogs/{{ blogPost.handle }}"
                  class="d-block text-dark text-decoration-none py-2 border-bottom"
                >
                  <!-- BLOG POST ROW -->
                  <p class="mb-0 d-none d-md-block">
                    {{ blogPost.created | ordinalDate }}
                  </p>
                  <div
                    class="d-flex align-items-stretch justify-content-start gap-3"
                  >
                    <div>
                      <img
                        src="{{ blogPost.imageUrl | noCache }}"
                        class="d-none d-sm-block w-100 flex-shrink-0"
                        style="max-width: 200px; min-width: 200px"
                      />
                      <img
                        src="{{ blogPost.imageUrl | noCache }}"
                        class="d-block d-sm-none w-100 mb-2 flex-shrink-0"
                        style="max-width: 150px; min-width: 120px"
                      />
                    </div>
                    <div
                      class="d-flex flex-fill flex-column align-items-start justify-content-start"
                    >
                      <p
                        class="link-underline text-decoration-none fs-5 text-decoration-none"
                      >
                        {{ blogPost.title }}
                      </p>
                      <small class="d-none d-md-block">
                        {{ blogPost.excerpt }}
                      </small>
                      <div class="flex-fill"></div>
                      <p class="mb-0 d-block d-md-none">
                        {{ blogPost.created | ordinalDate }}
                      </p>
                      <div
                        class="w-100 d-flex align-items-center justify-content-start justify-content-md-end"
                      >
                        <p
                          class="mb-0 text-decoration-none"
                          style="color: #327b8d"
                        >
                          Read On...
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="s-spacing"></div>

        <div
          *ngIf="faq && faq.length > 0"
          class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0"
        >
          <div class="px-0 px-md-2">
            <div class="pb-5 pt-5">
              <p class="text-center text-md-start fs-md-26 fs-mb-22">
                Frequently Asked Questions
              </p>
            </div>
            <div class="w-100 px-3 px-md-0 pb-5">
              <pla-faq [faq]="faq"></pla-faq>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  public faq: InfoDropdown[] = [
    // FaqBuilder.build('faq_1', '', [])
  ];
-->

<div *ngIf="false" class="page">
  <div class="content-container">
    <div class="side-container p-3 pt-0 position-relative">
      <div class="position-sticky">
        <pla-side-nav></pla-side-nav>
      </div>
    </div>
    <div class="center-content-container">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <pla-banner-image
          [carBanner]="'PORSCHE_918_S'"
          [rawImage]="true"
          [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height"
          >
            <!-- HEADER TEXT -->
            <div
              class="text-white text-center w-100 align-self-center justify-content-center"
            >
              <p class="h1 mb-1 fw-bold">Number Plate News</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  Hot off the Press Plate News and DVLA Updates
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-2"
            >
              <a
                style="color: #327b8d"
                class="d-block text-decoration-none"
                href="/"
                >Home</a
              >
              <span>/</span>
              <a
                style="color: #939393; white-space: pre"
                class="text-decoration-none d-block"
                [routerLink]="['/blogs']"
                >Number Plate News</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="content-container">
        <div class="center-content-container">
          <div
            class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-2"
          >
            <div *ngIf="activeBlogPosts().length === 0">
              <p class="text-center mt-4">No available blogs</p>
            </div>
            <div *ngIf="activeBlogPosts().length > 0">
              <ng-container *ngFor="let blogPost of activeBlogPosts()">
                <a
                  href="/blogs/{{ blogPost.handle }}"
                  class="d-block text-dark text-decoration-none py-2 border-bottom"
                >
                  <!-- BLOG POST ROW -->
                  <p class="mb-0 d-none d-md-block">
                    {{ blogPost.created | ordinalDate }}
                  </p>
                  <div
                    class="d-flex align-items-stretch justify-content-start gap-3"
                  >
                    <div>
                      <img
                        src="{{ blogPost.imageUrl | noCache }}"
                        class="d-none d-sm-block w-100 flex-shrink-0"
                        style="max-width: 200px; min-width: 200px"
                      />
                      <img
                        src="{{ blogPost.imageUrl | noCache }}"
                        class="d-block d-sm-none w-100 mb-2 flex-shrink-0"
                        style="max-width: 150px; min-width: 120px"
                      />
                    </div>
                    <div
                      class="d-flex flex-fill flex-column align-items-start justify-content-start"
                    >
                      <p
                        class="link-underline text-decoration-none fs-5 text-decoration-none"
                      >
                        {{ blogPost.title }}
                      </p>
                      <small class="d-none d-md-block">
                        {{ blogPost.excerpt }}
                      </small>
                      <div class="flex-fill"></div>
                      <p class="mb-0 d-block d-md-none">
                        {{ blogPost.created | ordinalDate }}
                      </p>
                      <div
                        class="w-100 d-flex align-items-center justify-content-start justify-content-md-end"
                      >
                        <p
                          class="mb-0 text-decoration-none"
                          style="color: #327b8d"
                        >
                          Read On...
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="side-container lg bg-light py-3">
          <div class="pb-2">
            <pla-featured-plates
              [featureType]="rsFeatured"
              [plateClass]="'flat shaded'"
              [plateSize]="'featured'"
              [priceFontSize]="12"
              [priceClass]="'text-muted fst-italic'"
              [maxCount]="14"
            ></pla-featured-plates>
          </div>
          <div class="border-top pt-2">
            <pla-featured-plates
              [featureType]="raFeatured"
              [plateClass]="'flat shaded'"
              [plateSize]="'featured'"
              [priceFontSize]="12"
              [maxCount]="14"
            ></pla-featured-plates>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image
      class="w-100"
      [rawImage]="true"
      [backgroundImage]="true"
      [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'"
    >
      <div
        class="d-flex align-items-stretch justify-content-end new-banner-height"
      >
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px"
          >
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>
