<div class="page" *ngIf="newPage">
  <pla-main-nav-chips [backLink]="registration ? '/compare' : undefined"></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav>
          <div first class="bg-light text-dark p-3 mb-4 border border-1 border-dark"
            *ngIf="registration && compareFilters && detail.totalResultCount > 0">
            <pla-side-bar-filters [filters]="compareFilters"></pla-side-bar-filters>
          </div>
        </pla-side-nav>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div *ngIf="false">
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a [style.color]="registration ? '#327b8d' : '#939393'" style="white-space: pre"
                class="text-decoration-none d-block" [routerLink]="['/compare']">Compare</a>
              <span *ngIf="registration">/</span>
              <span *ngIf="registration" style="color: #939393; white-space: pre"
                class="text-decoration-none d-block">{{ registration }}</span>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'HATE_TAX_DIABLO_DT'" [mobileCarBanner]="'HATE_TAX_DIABLO_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'compare-mobile-background desktop-mobile-bottom'"
          *ngIf="!registration">
          <div class="compare-banner-height">
            <div class="d-flex justify-content-center align-items-center d-md-none flex-column pt-2 pb-4">
              <div class="p-2">
                <p class="px-3 lh-sm fs-mb-23 text-white text-start mx-auto fw-normal">
                  COMPARE PRICES ON YOUR NEXT PRIVATE PLATE AND YOU COULD SAVE
                  OVER £172!
                </p>
              </div>
            </div>

            <div class="d-none d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white" style="max-width: 270px">
                  COMPARE<br />PRIVATE PLATE<br />PRICES AND YOU<br />COULD
                  SAVE<br />OVER<br />£172*
                </p>
              </div>
            </div>

            <!-- HEADER TEXT -->
            <div *ngIf="false" class="text-white text-center w-100 align-self-center justify-content-center">
              <p class="h1 mb-1 fw-bold">PlateX Compare</p>
              <div>
                <p class="fw-light fs-6 mb-0" style="color: #facf15">
                  Find the best number plate for the best price
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div *ngIf="!registration">
          <div class="px-4">
            <p class="mt-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-center text-md-center h-mb">
              Find Your Perfect Plate - We Search, You Compare
            </p>
          </div>
          <div class="px-0 px-md-2 mt-4 w-100 mx-auto" style="max-width: 1000px">
            <div class="px-4 px-md-3">
              <div class="w-100 mx-auto search-input">
                <div class="mb-3 mx-auto rounded-2 overflow-hidden plate-background flat shaded position-relative">
                  <div class="mx-auto d-flex align-items-stretch justify-content-between py-2">
                    <div class="bg-transparent w-100 p-1">
                      <pla-new-search-input [defaultInput]="plateToCompare" [route]="'compare/'"
                        [searchTrigger]="searchTrigger" [searchTexts]="['']"
                        [placeholder]="'Enter reg to compare prices'"></pla-new-search-input>
                    </div>
                    <button (click)="searchTrigger.emit()" style="max-width: 56px"
                      class="w-100 fs-md-15 fs-mb-15 btn text-dark d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                      <mat-icon data-nosnippet class="">search</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
        <div class="px-0 px-md-2" *ngIf="!registration">
          <div class="pb-3 pt-3 px-3 px-md-0" *ngIf="hasCompareHistory()">
            <div class="w-100">
              <pla-user-history [whitePlates]="true" [type]="'compare'" [title]="'Your Deals'"></pla-user-history>
            </div>
          </div>

          <div *ngIf="false" class="pb-3 pt-3 px-3 px-md-0">
            <p class="fs-mb-14 fs-md-16 mb-0">
              99.9% of private plates are offered for sale with more than one
              dealer. Find the best deal on over 72 million private plates
              listed with 35 registration dealers with Plate Compare
            </p>
          </div>

          <div class="s-spacing"></div>

          <!-- CARDS -->
          <div class="pb-3 pt-0 pt-md-3">
            <div class="container-fluid mx-auto w-100">
              <div class="row px-0">
                <p class="px-4 fs-md-26 fs-mb-24 lh-sm fw-bolder text-start h-mb">
                  How it works
                </p>
              </div>

              <div class="px-4 px-md-0">
                <ng-container *ngFor="let card of cards; let index = index">
                  <div class="row px-3 py-5" style="min-height: 290px"
                    [style.background]="index % 2 == 0 ? '#F1F4F2' : '#032933'">
                    <div class="px-5 px-md-0 col-12 col-sm-6 col-xl-3 bg-transparent d-flex align-items-center justify-content-center order-1 {{
                        index % 2 == 0 ? 'order-xl-1' : 'order-xl-3'
                      }}" [style.background]="
                        index % 2 == 0 ? '#F1F4F2' : '#032933'
                      ">
                      <img class="d-block mx-auto w-100 bg-transparent n_img" [src]="card.number" />
                    </div>

                    <div
                      class="py-3 py-md-3 px-4 px-md-5 col-12 col-sm-6 col-xl-4 d-flex align-items-center justify-content-center order-2 order-xl-2">
                      <img class="d-block mx-auto w-100 c_img" src="{{ card.image }}" />
                    </div>

                    <div class="mt-3 mt-xl-0 col-12 col-xl-5 d-flex align-items-center justify-content-center order-3 {{
                        index % 2 == 0 ? 'order-xl-3' : 'order-xl-1'
                      }}">
                      <div>
                        <p class="{{
                            index % 2 == 0 ? '' : 'text-white'
                          }} fw-bold fs-md-18 fs-mb-16 text-center text-xl-start lh-sm">
                          {{ card.title }}
                        </p>
                        <p class="{{
                            index % 2 == 0 ? '' : 'text-white'
                          }} fs-md-16 fs-mb-14 mb-0 text-center text-xl-start lh-sm">
                          {{ card.body }}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div style="height: 50px"></div>

          <div class="pb-5 pt-5">
            <p class="text-center text-md-start fs-md-26 fs-mb-22">
              Frequently Asked Questions
            </p>
          </div>
          <div class="w-100 px-3 px-md-0 pb-5">
            <pla-faq [faq]="faq"></pla-faq>
          </div>

          <div style="height: 50px"></div>
        </div>

        <!-- loaded content -->
        <div *ngIf="registration"
          class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0">
          <div class="px-2 px-md-1 mx-auto" style="max-width: 1000px">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-md-6 order-2 order-md-1">
                  <div class="w-100 h-100 p-3 text-center d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <pla-plate [rawPlate]="registration" [white]="false" [mobileSmall]="false" [openModal]="false"
                        [showPrice]="false" [useCustom]="true" [customClass]="'flat shaded'" [customSize]="9"
                        [customFontSize]="40"></pla-plate>
                    </div>

                    <div *ngIf="detail">
                      <p class="text-center mt-3 mb-0" *ngIf="detail.min !== 0">
                        Prices From
                      </p>
                      <p class="mb-0" *ngIf="detail.min !== 0 && detail.min != detail.max">
                        <strong class="fs-5 fw-bold">{{
                          detail.min | currency : "GBP"
                          }}</strong>
                        <span class="fs-md-20 fs-mb-20"> to </span>
                        <strong class="fs-5 fw-bold">{{
                          detail.max | currency : "GBP"
                          }}</strong>
                      </p>
                      <p class="mb-0" *ngIf="detail.min !== 0 && detail.min == detail.max">
                        <strong class="fs-5 fw-bold">{{
                          detail.min | currency : "GBP"
                          }}</strong>
                      </p>
                      <small class="text-muted">Details last updated on
                        {{ detail.lastUpdate | date : "dd/MM/yy" }}</small>
                    </div>

                    <div>
                      <a class="mt-3 fw-normal text-center mx-auto d-block fs-md-14 fs-mb-14 text-decoration-underline fst-italic"
                        style="color: #29576a !important" [routerLink]="'/search-results/' + registration">Search for
                        more registrations like
                        {{ registration }}</a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 order-1 order-md-2">
                  <div class="overflow-hidden bg-light position-relative" style="border-radius: 25px">
                    <img class="w-100 d-block m-auto" style="max-height: 6000px"
                      *ngIf="selectedImage && selectedImage.image" [src]="selectedImage.image" />
                    <div class="position-absolute top-0 end-0 p-2" *ngIf="favouritePlate">
                      <div class="bg-white rounded-circle d-flex align-items-center justify-content-center"
                        style="width: 35px; height: 35px">
                        <pla-inline-favourite [showText]="false" [force]="'favourite'"
                          [plate]="favouritePlate"></pla-inline-favourite>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- PLATE INFO -->
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-stretch mt-2 bg-white position-sticky start-0 end-0"
            style="top: 54px; z-index: 1000;">
            <pla-sticky-tabs class="flex-fill w-100" [config]="stickyLinkConfig" [classes]="'pt-3 pb-3'">
              <div extraChips1
                class="flex-shrink-0 d-flex d-lg-none align-items-center justify-content-center px-3 px-md-3 py-2 rounded-pill hover-interact pointer"
                [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
                [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
                style="cursor: pointer; border-style: solid;" (click)="filterTrigger.emit()">
                <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important;"
                  class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                  [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">tune</mat-icon>
              </div>
            </pla-sticky-tabs>
            <div class="dd-raw my-3 position-relative me-3 d-none d-lg-block">
              <div class="flex-shrink-0 px-3 px-md-3 py-2 rounded-pill hover-interact pointer position-relative"
                [style.background]="'#F1F4F2'" [style.borderColor]="'#F1F4F2'"
                [style.borderWidth]="stickyLinkConfig.metaData.defaultStyle.borderWidth + 'px'"
                style="cursor: pointer; border-style: solid;" (click)="sortTrigger.emit()">
                <div class="d-flex align-items-center justify-content-center gap-2">
                  <mat-icon data-nosnippet style="max-height: 14px; line-height: 14px !important; max-width: 15px;"
                    class="my-0 d-block lh-sm text-center text-dark fs-md-20 fs-mb-18"
                    [style.color]="stickyLinkConfig.metaData.defaultStyle.textColour">swap_vert</mat-icon>
                  Sort
                </div>
              </div>
              <pla-custom-dropdown [containerClass]="'border border-1 shadow rounded-3 overflow-hidden mt-1'"
                [end]="true" [config]="sortConfig" [remoteOpen]="sortTrigger" (change)="sortChanged($event)"
                [raw]="true"></pla-custom-dropdown>
            </div>
          </div>
          <div>
            <div class="pb-3 w-100 position-relative" style="background-color: #f1f4f2" id="compare-deals-content">
              <div class="position-absolute" style="top: -92px" id="section-compare-deals"></div>
              <pla-tp-sellers *ngIf="registrationLoaded" [openFilters]="filterTrigger" [sortChange]="sortChange"
                (PriceRangeChange)="PriceRangeChange($event)" (resultsChange)="CompareResultsChange($event)"
                (compareFilters)="SetCompareFilters($event)" [registration]="registration"></pla-tp-sellers>
            </div>

            <!-- ALT REG - Disabled -->
            <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative border-top"
              id="alternative-registrations-content"
              *ngIf="false && registrationLoaded && DetailLoaded() && detail.max <= 0">
              <div class="position-absolute" style="top: -92px" id="section-alternative-registrations"></div>
              <div class="py-5">
                <pla-search-result-table [canRank]="true" [searching]="altSearching" [searchTitle]="registration"
                  [searchTableType]="'main'" [searchType]="''" [hideOnEmpty]="true" [resultType]="'CLOSE MATCHES'"
                  [pluralResultType]="'CLOSE MATCHES THAT ARE AVAILABLE'" [results]="altSearchResults"
                  [showRatings]="true" [paginationPageSize]="5" [resultChange]="altResultChange"
                  [topResultSet]="false"></pla-search-result-table>
              </div>
            </div>

            <div class="pt-3 pb-3 w-100 px-3 px-md-0 position-relative" id="history-content">
              <div class="position-absolute" style="top: -92px" id="section-history"></div>

              <div class="mx-auto pt-4">
                <h4 class="my-0 fs-5">Price History</h4>

                <div class="mt-4 rounded-3 border border 1 px-0 px-md-4 shadow-sm" style="
                    border-top: 3px solid #0a6077 !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                  ">
                  <div class="d-flex align-items-stretch justify-content-between gap-4 pt-4 flex-column flex-md-row"
                    *ngIf="registrationLoaded && DetailLoaded()">
                    <div class="flex-shrink-0 px-4 px-md-0 order-1 order-md-0">
                      <div class="ms-0 me-auto d-flex">
                        <pla-plate [rawPlate]="registration" [white]="false" [mobileSmall]="false" [openModal]="false"
                          [showPrice]="false" [useCustom]="true" [customClass]="'flat shaded'" [customSize]="9"
                          [customFontSize]="40"></pla-plate>
                      </div>

                      <p *ngIf="isRegisteredToVehicle" class="fs-md-16 text-start mt-4 mb-0">
                        {{ registration }} is currently registered to a
                        {{ registeredVehicleInfo.data.colour }}
                        {{ registeredVehicleInfo.data.make }}
                      </p>

                      <table *ngIf="detail.plateDetail" class="text-start w-100 mt-4 mb-3 ms-0 me-auto"
                        style="max-width: 350px">
                        <tr>
                          <td class="fs-6 fw-normal pe-2">
                            Registration Style
                          </td>
                          <td class="fs-6 text-start ps-2">
                            {{ detail.plateDetail.style }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-6 fw-normal pe-2">First Issued</td>
                          <td class="fs-6 text-start ps-2">
                            {{ detail.plateDetail.issueMonth }}
                            {{ detail.plateDetail.issueYear }}
                          </td>
                        </tr>
                        <tr>
                          <td class="fs-6 fw-normal pe-2">Region</td>
                          <td class="fs-6 text-start ps-2">
                            {{ detail.plateDetail.issueRegion }}
                          </td>
                        </tr>

                        <tr>
                          <td class="py-2"></td>
                        </tr>

                        <ng-container *ngIf="false && 
                            visibleChartData && visibleChartData.showingChart
                          ">
                          <tr>
                            <td class="fs-6 fw-normal pe-2">Overall Gain</td>
                            <td class="fs-6 text-start ps-2">£X,XXX</td>
                          </tr>
                          <tr>
                            <td class="fs-6 fw-normal pe-2">ROI</td>
                            <td class="fs-6 text-start ps-2">XXX%</td>
                          </tr>
                          <tr>
                            <td class="fs-6 fw-normal pe-2">Annualised ROI</td>
                            <td class="fs-6 text-start ps-2">X.X%</td>
                          </tr>
                        </ng-container>
                      </table>
                    </div>
                    <div class="flex-fill px-2 px-md-0 order-0 order-md-1 pb-2">
                      <pla-price-history *ngIf="registrationLoaded" [registration]="registration"
                        (showingData)="chartDataUpdate($event)">
                        <div *ngIf="false" noChart>
                          <p>
                            This plate doesnt have enough history to generate a chart, see
                            below to
                            see price points available.
                          </p>
                        </div>
                        <div noTable>
                          <p>There is no verified sales data for this registration.</p>
                        </div>
                      </pla-price-history>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative border-top" id="market-insight-content"
              *ngIf="false && registrationLoaded">
              <div class="position-absolute" style="top: -92px" id="section-market-insight"></div>
              <div>
                <p class="my-4 fs-5">Market Insights</p>

                <div class="mt-4 rounded-3 border border 1 px-0 px-md-4 shadow-sm" style="
                    border-top: 3px solid #0a6077 !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    background-color: #fcf8ef;
                  ">
                  <div class="d-flex align-items-stretch justify-content-between gap-4 pt-4 flex-column flex-md-row"
                    *ngIf="registrationLoaded">
                    <div style="min-width: 300px; max-width: 300px" class="px-4 px-md-0 order-1 order-md-0">
                      <p class="fs-md-18">Market Share</p>
                      <p>
                        Current style registrations makeup 40% of the available
                        registrations available. Since the change in
                        registration from Prefix there has been “44” new
                        registration issues from 02 - 25 for the March change
                        and 51 - 74 September year change and this number will
                        only continue to increase with year sequence allowing
                        new current style registrations until 2049.
                      </p>

                      <div *ngIf="marketShareData && plateStyle" class="text-start mt-3 fs-md-18 fs-mb-18">
                        <div class="mb-3 border-bottom border-1"></div>
                        <p class="mb-0">
                          Total Resale Market Value:
                          {{
                          (marketShareData.current.sum +
                          marketShareData.prefix.sum +
                          marketShareData.suffix.sum +
                          marketShareData.dateless.sum) /
                          100 | currency : "GBP"
                          }}
                        </p>
                        <p>
                          {{ plateStyle | titlecase }} Style Value:
                          {{
                          marketShareData[plateStyle].sum / 100
                          | currency : "GBP"
                          }}
                        </p>

                        <div class="mt-3 mb-3 border-bottom border-1"></div>

                        <div class="overflow-hidden rounded-2 text-white mb-3">
                          <p style="background-color: #3889d7;"
                            class="mb-0 text-white px-2 border-bottom border-2 border-white">
                            {{ 'current' | titlecase }} Style Volume:
                            {{ marketShareData.current.count | number }}
                          </p>
                          <p style="background-color: #f79f01;"
                            class="mb-0 text-white px-2 border-bottom border-2 border-white">
                            {{ 'prefix' | titlecase }} Style Volume:
                            {{ marketShareData.prefix.count | number }}
                          </p>
                          <p style="background-color: #84194e;"
                            class="mb-0 text-white px-2 border-bottom border-2 border-white">
                            {{ 'suffix' | titlecase }} Style Volume:
                            {{ marketShareData.suffix.count | number }}
                          </p>
                          <p style="background-color: #77aaa8;" class="mb-0 text-white px-2">
                            {{ 'dateless' | titlecase }} Style Volume:
                            {{ marketShareData.dateless.count | number }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div style="min-width: 300px" class="flex-fill px-2 px-md-0 order-0 order-md-1 pb-2">
                      <pla-market-share (dataUpdated)="marketShareDataUpdate($event)"></pla-market-share>
                    </div>
                  </div>
                </div>

                <div class="mt-4 rounded-3 border border 1 px-0 px-md-4 shadow-sm" style="
                    border-top: 3px solid #0a6077 !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    background-color: #f1f4f2;
                  ">
                  <div class="d-flex align-items-stretch justify-content-between gap-4 pt-4 flex-column flex-md-row"
                    *ngIf="registrationLoaded && DetailLoaded()">
                    <div style="min-width: 300px; max-width: 300px" class="px-4 px-md-0 order-1 order-md-0">
                      <p class="fs-md-18">Price</p>
                      <p>
                        New DVLA current style registrations tend to be launched
                        from new prices from £399 to £3,999 at fixed price but
                        particular permutations that make up names or words can
                        be auction for many thousands.
                      </p>
                    </div>

                    <div class="w-100 flex-fill px-2 px-md-0 order-0 order-md-1 pb-2">
                      <pla-market-insight *ngIf="registrationLoaded && detail.loaded" [registration]="registration"
                        [cheapestRegPrice]="detail.min"></pla-market-insight>
                    </div>
                  </div>
                </div>

                <div *ngIf="false" class="mt-4 rounded-3 border border 1 px-0 px-md-4 shadow-sm" style="
                    border-top: 3px solid #0a6077 !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    background-color: #faf1ed;
                  ">
                  <div class="d-flex align-items-stretch justify-content-between gap-4 pt-4 flex-column flex-md-row"
                    *ngIf="registrationLoaded && DetailLoaded()">
                    <div style="min-width: 300px; max-width: 300px" class="px-4 px-md-0 order-1 order-md-0">
                      <p class="fs-md-18">Performance</p>
                      <p>
                        These examples highlight the potential financial gains
                        from speculative purchases of new issue private number
                        plates, especially those resembling popular names or
                        words. This market research shows that, with careful
                        selection, private plates can be a highly attractive
                        alternative investment.
                      </p>
                    </div>

                    <div class="flex-fill px-2 px-md-0 order-0 order-md-1 pb-2">
                      <i>PRICE INDEX CHART</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative bg-white border-top" id="faq-content">
              <div class="position-absolute" style="top: -92px" id="section-faq"></div>
              <div>
                <p class="py-5 fs-5">FAQ's</p>
                <pla-faq [faq]="faq"></pla-faq>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noRegistration>
    <!-- Page steps -->
    <div class="pla_card no-card mobile spacing padding">
      <div class="text-section">
        <div class="text-title heading">
          <h2>Compare</h2>
        </div>
        <div class="text-paragraph">
          <p>
            Simply enter the exact registration (taking care to only use the
            number 0 and letter O correctly) to find the best deal available for
            your perfect registration.
          </p>
        </div>

        <div class="text-paragraph">
          <div class="faq-item">
            <mat-icon data-nosnippet>help_outline</mat-icon>
            <h4>How do you show so many registrations prices?</h4>
          </div>
          <p class="indent">
            Most registration prices are updated automatically while others by
            our team at least once a day, some several times an hour such as
            those in an active DVLA auction. However, dealers may update their
            prices more often and consequently PlateX does not guarantee that
            prices are correct. Always double-check the price on the dealer's
            website before completing a purchase to ensure that the price you
            saw at PlateX is correct.
          </p>

          <div class="faq-item">
            <mat-icon data-nosnippet>help_outline</mat-icon>
            <h4>Are the prices shown fully inclusive of VAT and shipping?</h4>
          </div>
          <p class="indent">
            While we endeavour to show all prices inclusive of shipping for
            technical or resource reasons, some dealer's price may miss shipping
            cost information or only be included at checkout. In more rare cases
            some dealer prices may exclude VAT in our listings. If we are
            informed that a dealer has not displayed prices with VAT, we will
            add a note to “check with the dealer” to prompt users to refer to
            the dealer's site yourself to check whether VAT and shipping costs
            are inclusive. PlateX currently compares the prices of 68 million
            unique registrations from over 40 dealers, the DVLA and auctions. In
            some instances there may only be a single price for a registration
            if the dealer chooses to be the sole supplier of a registration, a
            private sales listing or a DVLA auction.
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>