import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pla-dealer-portal',
  templateUrl: './dealer-portal.component.html',
  styleUrls: ['./dealer-portal.component.scss']
})
export class DealerPortalComponent implements OnInit {
  public registrationString: string;
  public search = new EventEmitter<string>();

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(_p => {
      this.registrationString = _p['registration'];
      setTimeout(() => {
        this.registrationString = this.registrationString.toUpperCase();
        if (this.registrationString != null) this.search.emit(this.registrationString);
      }, 500);
    })
  }

  ngOnInit(): void {
  }

  public lookUp(): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { registration: this.registrationString.toUpperCase() },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

}
