<div class="mb-3 mx-auto">
  <div class="w-100 card shadow-sm rounded w-content position-relative"
    style="min-width: 250px; max-width: 8000px; height: 150px">
    <div class="p-2 d-flex align-items-top justify-content-between" *ngIf="today && yesterday">
      <div>
        <div class="d-flex align-items-top justify-content-between text-muted">
          <div class="me-2 h5 mb-0">
            <mat-icon data-nosnippet>{{ icon }}</mat-icon>
          </div>
          <span class="h5 mb-0 lh-sm">{{ title }}</span>
          <div class="flex-fill"></div>
        </div>
        <p class="text-muted mb-0 vs lh-sm">
          <span class="number">{{ today.y | number: '1.0':'en-US' }}</span>
          {{ interaction.toLowerCase().toPlural(today.y) }} today
        </p>
        <div class="text-dark" style="z-index: 1; pointer-events: none;">
          <h1 class="mb-3 text-start number">
            <strong class="fw-bold">{{ all | number: '1.0':'en-US' }}</strong>
          </h1>
        </div>
      </div>

      <div class="d-flex flex-column mb-0 {{
          todayAnalytic > 0
            ? 'text-success'
            : todayAnalytic === 0
            ? 'text-warning'
            : 'text-danger'
        }}">
        <div class="d-flex align-items-center justify-content-between">
          <div class="flex-fill"></div>
          <mat-icon data-nosnippet>{{
            todayAnalytic > 0
            ? "arrow_upward"
            : todayAnalytic === 0
            ? "compare_arrows"
            : "arrow_downward"
            }}</mat-icon>
          <span class="h5 mb-0 ms-1 number">{{ todayAnalytic }}%</span>
        </div>
        <span class="vs">From yesterday</span>
      </div>
    </div>
    <div class="position-absolute bottom-0 left-0 right-0"
      style="margin-left: -1px; margin-right: -1px; width: calc(100% + 2px)">
      <div class="position-relative" id="chart-{{containerId}}">
        <canvas baseChart class="w-100" style="max-height: 100px; min-width: 300px" [type]="'line'"
          [options]="chartOptions" [data]="chartData" [legend]="false"></canvas>
      </div>
    </div>
  </div>
  <div class="mt-1">
    <span class="w-100 text-muted d-block text-center">{{ description }}</span>
  </div>
</div>