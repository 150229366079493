<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <!-- <div class="bg-light py-3">
        <pla-featured-plates [featureType]="raFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="false"></pla-featured-plates>
      </div> -->
    </div>
    <div class="center-content-container position-relative">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
        <div class="pla_card no-card mobile spacing px-2">
          <!-- CONTENT -->
          <div class="w-100" *ngIf="blog">
            <div
              class="pla_card mt-0 pt-0 mobile spacing no-top no-card no-left no-bottom no-right position-relative px-2 px-md-4 pb-2 text-black w-100 align-self-start justify-content-start me-auto ms-auto ms-xl-0 me-xl-auto">
              <a href="/blogs"
                class="text-decoration-none lh-sm mb-2 d-flex align-items-center justify-content-start gap-1"><mat-icon
                  class="x-small">chevron_left</mat-icon>All Blogs</a>
              <p class="d-block">{{ blog.created | ordinalDate }}</p>
              <p class="fs-md-26 fs-mb-24 lh-sm fw-bolder text-center text-md-start mb-1">{{ blog.title }}</p>

              <img src="{{ blog.imageUrl | noCache }}" [alt]="blog.title"
                class="d-block d-md-none mx-auto mx-md-0 w-100 px-4" style="max-width: 375px" />
            </div>

            <div
              class="pla_card mobile spacing no-top no-card no-bottom no-left no-right position-relative px-4 px-md-4 py-4 blog-content me-auto ms-auto ms-xl-0 me-xl-auto">
              <ng-container *ngFor="let paragraph of blog.paragraphs; let index = index">
                <ng-container *ngIf="index == blog.imageParagraphIndex">
                  <div class="d-block d-md-flex align-items-start justify-content-between gap-3">
                    <div class="flex-grow-1">
                      <p class="lh-lg fw-light fs-md-16 fs-mb-14 mb-4" [innerHTML]="paragraph"></p>
                    </div>
                    <img src="{{ blog.imageUrl | noCache }}" [alt]="blog.title"
                      class="d-none d-md-block mx-auto mx-md-0 mb-3" style="max-width: 300px" />
                  </div>
                </ng-container>
                <ng-container *ngIf="index != blog.imageParagraphIndex">
                  <p class="lh-lg fw-light fs-md-16 fs-mb-14 mb-4" [innerHTML]="paragraph"></p>
                </ng-container>
              </ng-container>

              <div class="mx-auto w-100" *ngIf="blog.html">
                <div class="mx-auto w-100" [innerHTML]="blog.html"></div>
              </div>

              <!-- <div class="mt-4 d-none">
                <a href="/blogs" style="max-width: 200px" class="d-block mx-auto btn btn-success">All Blogs</a>
              </div> -->
            </div>

            <div *ngIf="blogFeatured" class="px-2 px-md-0 pt-2 pb-4 me-auto ms-auto ms-xl-0 me-xl-auto">
              <pla-featured-plates [showTitle]="false" [showIfEmpty]="false" [featureType]="blogFeatured"
                [plateSize]="'featured'" [showPrice]="false" [plateClass]="'flat shaded'"></pla-featured-plates>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pla-featured-blogs>
    <div title>
      <p class="fw-bold mb-2 text-decoration-none d-block text-dark">In Other News</p>
    </div>
  </pla-featured-blogs>
</div>