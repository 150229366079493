import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  PredictiveSearch,
  SearchService,
} from 'src/app/services/search-service';

export class OptionSearchOption {
  constructor(public viewValue: string, public value: string) {}
}

@Component({
  selector: 'pla-option-search',
  templateUrl: './option-search.component.html',
  styleUrls: ['./option-search.component.scss'],
})
export class OptionSearchComponent implements OnInit {
  @Input() public dropdown: boolean = false;
  @Input() public options: OptionSearchOption[] = [];
  @Input() public optionsEnabled: boolean;
  @Input() public searchCriteria: string;
  @Input() public endpoint: string;
  @Input() public showHeaderText: boolean = true;
  @Input() public inlineComponent: boolean = false;
  @Input() public defaultSizing: boolean = true;
  @Input() public acceptEmpty: boolean = false;
  @Input() public plateStyle: boolean = false;
  @Input() public deleteOnEnter: boolean = false;
  @Input() public smaller: boolean = false;
  @Input() public placeholder: string = 'SEARCH HERE';

  @Input() public selectedOption: OptionSearchOption;
  @Input() public loading: boolean = false;
  @Input() public gotoProductPage: boolean = true;
  @Input() public stayInPlace: boolean = false;

  @Output() public searched: EventEmitter<void> = new EventEmitter<void>();

  public predictiveOptions: PredictiveSearch[] = [];
  private debouncedSearchTimeout: number = -1;

  constructor(private router: Router, private searchService: SearchService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    if (this.selectedOption == null) this.selectedOption = this.options[0];
  }

  public HandleFocus(): void {
    if (this.stayInPlace) return;
    window.scrollTo(0, 1);
    document.body.scrollTop = 0;

    setTimeout(() => {
      window.scrollTo(0, 1);
      document.body.scrollTop = 0;
    }, 100);
  }

  public EnterSubmit(event: KeyboardEvent): void {
    // debounce event to predict search
    // window.clearTimeout(this.debouncedSearchTimeout);
    // this.debouncedSearchTimeout = window.setTimeout(() => {
    //   this.predictSearch(() => {});
    // }, 500);

    if (event.code === 'Enter') {
      event.stopImmediatePropagation();
      this.SubmitForm();
    }
  }

  private predictSearch(callback: (isPlate: boolean) => void): void {
    this.searchService.getPredictiveSearch(
      this.searchCriteria,
      (_: PredictiveSearch[]) => {
        callback(_ != null && _.length > 0);
      }
    );
  }

  public GotoPlateDetail(registration: string): void {
    this.router
      .navigate([`/compare/${registration.replace(' ', '')}`], {
        queryParams: { _l: 'direct' },
      })
      .then(() => {
        this.searched.emit();
        if (this.deleteOnEnter) this.searchCriteria = '';
      });
  }

  public SubmitForm(): void {
    if (this.dropdown) {
      if (this.selectedOption == null) return;
      this.router
        .navigate([`/${this.endpoint}/${this.selectedOption.value}`])
        .then(() => {
          this.searched.emit();
        });
    } else {
      if (
        !this.acceptEmpty &&
        (this.searchCriteria == '' || this.searchCriteria == null)
      )
        return;

      this.loading = true;
      this.predictSearch((isSearchPlate: boolean) => {
        this.loading = false;
        if (isSearchPlate && this.gotoProductPage) {
          this.GotoPlateDetail(this.searchCriteria.replace(' ', ''));
        } else {
          this.router
            .navigate([`/${this.endpoint}/${this.searchCriteria}`])
            .then(() => {
              this.searched.emit();
              if (this.deleteOnEnter) this.searchCriteria = '';
            });
        }
      });
    }
  }

  public ScrollToTop(): void {
    setTimeout(() => {
      console.log('SCROLL TO TOP');
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }, 10);
  }
}
