import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import BlogPost from '../models/blogPost';
import { BlogService } from '../services/blog-service';
import Swiper, { SwiperOptions } from 'swiper';

@Component({
  selector: 'pla-featured-blogs',
  templateUrl: './featured-blogs.component.html',
  styleUrls: ['./featured-blogs.component.scss']
})
export class FeaturedBlogsComponent implements OnInit {

  @Input() public blogPosts: BlogPost[] = [];
  public swiperNav: { start: boolean; end: boolean } = {
    start: true,
    end: false,
  };

  public swiperNavigation = {
    nextEl: '.swiper-nav.swiper-next',
    prevEl: '.swiper-nav.swiper-prev',
  };

  public swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: false,
    loop: false,
    loopFillGroupWithBlank: true,
    navigation: this.swiperNavigation,
    enabled: true,
    spaceBetween: 50,
    setWrapperSize: true,
    centeredSlides: false,
    on: {
      slideChange: (swiper: Swiper) => {
        this.swiperNav = { start: swiper.isBeginning, end: swiper.isEnd };
        this.changeDetection.detectChanges();
      },
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        centeredSlides: true,
      },
      580: {
        slidesPerView: 1.5,
        spaceBetween: 10,
        centeredSlides: false,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      820: {
        slidesPerView: 2,
        spaceBetween: 60,
      },
      1000: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      1260: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
    },
  };

  constructor(private blogService: BlogService,
    private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.blogPosts.length != 0) return;
    this.blogService.GetArticles().subscribe((posts: BlogPost[]) => {
      this.blogPosts = posts.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    });
  }

  public activeblogPosts(): BlogPost[] {
    return this.blogPosts.filter(b => b.active);
  }

}
