export default class TrackingMetric {
  registration: string;
  impressionCount: number = 0;
  comparedCount: number = 0;
  clickCount: number = 0;
  affiliateCount: number = 0;
  affiliateLinkClicks: string[];
  day: Date;

  public Build(tm: TrackingMetric): TrackingMetric {
    this.registration = tm.registration;
    this.impressionCount = tm.impressionCount;
    this.comparedCount = tm.comparedCount;
    this.clickCount = tm.clickCount;
    this.affiliateCount = tm.affiliateCount;
    this.affiliateLinkClicks = tm.affiliateLinkClicks;
    this.day = new Date(tm.day);
    return this;
  }

  public AddRegistration(reg: string): TrackingMetric {
    this.registration = reg;
    return this;
  }

  public AddDate(date: Date): TrackingMetric {
    this.day = new Date(date);
    return this;
  }
}
