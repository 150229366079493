<div class="position-relative tp-sellers">
  <!-- MOBILE FILTER BUTTON -->
  <div *ngIf="false && resultsCanShow && !loading" [style.display]="mobileFilters ? 'none' : 'block'"
    class="position-sticky start-0 end-0 bg-white shadow-sm align-items-center" style="z-index: 51; top: 122px">
    <div class="d-flex justifty-content-center align-items-stretch flex-row">
      <div class="flex-fill w-100 d-flex d-lg-none border-end">
        <pla-custom-dropdown [config]="sortConfig" (change)="changeSort($event)"
          class="d-block w-100"></pla-custom-dropdown>
      </div>

      <div class="flex-fill w-100 d-flex d-lg-none align-items-center justify-content-center gap-2 py-2 text-dark px-2"
        (click)="ToggleMobileFilters(true)">
        <mat-icon data-nosnippet class="small">filter_alt</mat-icon>
        <small class="mb-0 text-center">Filter</small>
      </div>
    </div>
  </div>

  <div class="pt-3 px-3 px-md-1" style="background-color: white !important">
    <div class="mx-auto" style="max-width: 1000px" *ngIf="loading || !resultsCanShow">
      <pla-compare-loader [almostComplete]="searchAlmostComplete" (complete)="CompleteLoad()"></pla-compare-loader>
    </div>
  </div>

  <div class="px-3 px-md-1">
    <div class="px-0 px-md-4 mx-auto" *ngIf="loading || !resultsCanShow">
      <div class="mx-auto" style="max-width: 1000px">
        <div>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
          <ng-container *ngTemplateOutlet="skeletonRow"></ng-container>
        </div>
      </div>
    </div>
    <div class="px-0 px-md-4 mx-auto" *ngIf="!loading && resultsCanShow">
      <ng-container *ngIf="AvailableResults() <= 0">
        <div class="mb-5 rounded-lg p-3 d-block d-md-flex align-items-center justify-content-center gap-4 shadow-sm"
          style="background-color: #e5f1ee">
          <p class="my-0 fs-md-16 text-center text-md-start">
            Get notified when this registration is available
          </p>
          <div class="flex-grow-0 flex-shrink-0 mx-md-0 mx-auto mt-md-0 mt-3 d-block w-100" style="max-width: 150px">
            <button (click)="alert('check logged in status and notify')" mat-raised-button
              class="text-decoration-none dark-green-button mx-auto d-block w-100">
              Notify Me
            </button>
          </div>
        </div>
      </ng-container>

      <div
        class="mx-auto d-none d-lg-flex align-items-center justify-content-between justify-content-md-end gap-3 flex-wrap flex-md-nowrap"
        style="max-width: 1000px">
        <div class="pt-4">
          <p class="mb-0">
            {{
            AvailableResults() > 0
            ? AvailableResults() !== undefined
            ? AvailableResults()
            : "0"
            : "0"
            }}
            Results
          </p>
        </div>
        <div class="flex-fill"></div>
      </div>

      <!-- RESULTS -->
      <div class="accordion accordion-flush" id="tp-seller-accordion">
        <ng-container *ngIf="compareResults.length == 0 && viewResults.length == 0">
          <p class="text-center fs-md-16 my-2">
            No information is available for this registration
          </p>
        </ng-container>
        <ng-container *ngFor="let compareResult of viewResults; let i = index">
          <div *ngIf="i < visibleResults() || (i >= visibleResults() && seeAll)" class="position-relative">
            <!-- DESKTOP -->
            <div
              class="{{
                compareResult.total <= 0 ? 'opacity-25' : ''
              }} d-none d-md-flex justify-content-between align-items-stretch bg-white shadow-sm mb-3 mt-4 px-1 py-2 text-center"
              style="border-radius: 20px">
              <!-- TITLE + TP -->
              <div
                class="border-end border-super-light flex-fill px-3 py-2 d-flex flex-column justify-content-center align-items-center w-100 overflow-hidden"
                style="min-width: 160px; max-width: 235px">
                <span class="d-block text-dark text-center mb-2 fs-5">{{
                  compareResult.viewName
                  }}</span>
                <div class="mx-auto" (click)="openTPLink(compareResult)">
                  <img style="max-width: 90px" src="{{ trustPilotScoreImage(compareResult) }}"
                    class="d-block mx-auto" />
                  <small class="text-decoration-underline d-block">{{
                    compareResult.trustPilotRatingCount | number : "1.0-0"
                    }}
                    reviews</small>
                </div>
              </div>

              <!-- FINANCE -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-center align-items-center w-100">
                <mat-icon data-nosnippet class="{{ iconClasses }}"
                  [style.color]="compareResult.finance ? '#44A43B' : '#C11414'">{{ compareResult.finance ? "done" :
                  "close" }}</mat-icon>
                <small class="mt-2">Offers Finance</small>
              </div>

              <!-- DVLA RECOGNISED -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-center align-items-center w-100">
                <mat-icon data-nosnippet class="{{ iconClasses }}" [style.color]="true ? '#44A43B' : '#C11414'">{{ true
                  ? "done" : "close" }}</mat-icon>
                <small class="mt-2">DVLA Recognised</small>
              </div>

              <!-- Managed Transfer -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                <mat-icon data-nosnippet *ngIf="
                    (compareFilterValues != undefined &&
                      compareFilterValues.managedTransfer) ||
                    compareResult.transferService == -1
                  " class="{{ iconClasses }}" [style.color]="
                    compareResult.transferService > -1 ? '#44A43B' : '#C11414'
                  ">{{
                  compareResult.transferService > -1
                  ? compareFilterValues != undefined &&
                  compareFilterValues.managedTransfer
                  ? "done"
                  : "close"
                  : "close"
                  }}</mat-icon>
                <small class="mt-2" *ngIf="
                    (compareFilterValues == undefined ||
                      !compareFilterValues.managedTransfer) &&
                    compareResult.transferService > 0
                  ">{{
                  compareResult.transferService / 100 | currency : "GBP"
                  }}</small>
                <small class="mt-2" *ngIf="
                    (compareFilterValues == undefined ||
                      !compareFilterValues.managedTransfer) &&
                    compareResult.transferService == 0
                  ">FREE</small>
                <small class="mt-2">Managed Transfer</small>
              </div>

              <!-- STANDARD PLATES -->
              <div style="max-width: 90px"
                class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
                <mat-icon data-nosnippet *ngIf="
                    (compareFilterValues != undefined &&
                      compareFilterValues.standardPlates) ||
                    compareResult.physicalPlatePrice == -1
                  " class="{{ iconClasses }}" [style.color]="
                    compareResult.physicalPlatePrice > -1
                      ? '#44A43B'
                      : '#C11414'
                  ">{{
                  compareResult.physicalPlatePrice > -1
                  ? compareFilterValues != undefined &&
                  compareFilterValues.standardPlates
                  ? "done"
                  : "close"
                  : "close"
                  }}</mat-icon>
                <small class="mt-2" *ngIf="
                    (compareFilterValues == undefined ||
                      !compareFilterValues.standardPlates) &&
                    compareResult.physicalPlatePrice > 0
                  ">{{
                  compareResult.physicalPlatePrice / 100 | currency : "GBP"
                  }}</small>
                <small class="mt-2" *ngIf="
                    (compareFilterValues == undefined ||
                      !compareFilterValues.standardPlates) &&
                    compareResult.physicalPlatePrice == 0
                  ">FREE</small>
                <small class="mt-2">Standard Plates</small>
              </div>

              <div
                class="d-block d-xl-flex align-items-center justify-content-center flex-row flex-fill ps-2 w-100 py-2 px-2 gap-3"
                style="min-width: 200px">
                <div>
                  <p class="mb-2 mb-xl-0 fw-bold text-dark fs-5" *ngIf="compareResult.total > 0">
                    {{
                    compareResult.total
                    | currency
                    : "GBP"
                    : "symbol"
                    : (compareResult.total < 10000 ? "" : "1.0-0" ) }} </p>
                      <p class="mb-2 mb-xl-0" *ngIf="compareResult.total <= 0">
                        Unavailable
                      </p>
                </div>
                <div style="
                    background-color: #3791a8;
                    max-width: 150px;
                    cursor: pointer;
                  " class="w-100 rounded-pill py-2 mx-xl-0 mx-auto mt-2 mt-xl-0"
                  (click)="openCompareSiteHandler(compareResult)">
                  <p class="mb-0 text-white text-center">Visit Dealer</p>
                </div>
              </div>
            </div>
            <!-- END DESKTOP -->

            <!-- MOBILE -->
            <div class="{{
                compareResult.total <= 0 ? 'opacity-25' : ''
              }} d-block d-md-none bg-white shadow-sm mb-3 mt-2 p-3 text-center" style="border-radius: 20px">
              <button (click)="SelectMobileResult(compareResult)"
                class="w-100 shadow-none d-flex border-0 align-items-center justify-content-between pb-1 bg-white px-0">
                <div
                  class="d-flex align-items-center justify-content-center down-arrow black flex-grow-0 flex-shrink-0 me-2">
                </div>
                <div>
                  <span class="d-block fw-normal text-dark text-start mb-0 fs-5 text-truncate"
                    style="max-width: 150px">{{ compareResult.viewName }}</span>
                </div>

                <div class="flex-fill ps-2 w-100 text-end d-flex align-items-center justify-content-end gap-1">
                  <span class="d-block fw-bolder text-dark text-end mb-0 fs-5" *ngIf="compareResult.total > 0">
                    {{
                    compareResult.total
                    | currency
                    : "GBP"
                    : "symbol"
                    : (compareResult.total < 10000 ? "" : "1.0-0" ) }} </span>
                      <span class="d-block fw-bolder text-dark text-end mb-0 fs-5" *ngIf="compareResult.total <= 0">
                        Unavailable
                      </span>

                      <div style="height: 1px; width: 16px"></div>
                </div>
              </button>

              <!-- BOTTOM -->
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-center down-arrow flex-grow-0 flex-shrink-0 me-2">
                </div>
                <div class="ms-0 me-auto" (click)="openTPLink(compareResult)">
                  <img style="max-width: 70px" src="{{ trustPilotScoreImage(compareResult) }}"
                    class="d-block ms-0 me-auto" />
                  <small class="text-decoration-underline d-block">{{
                    compareResult.trustPilotRatingCount | number : "1.0-0"
                    }}
                    reviews</small>
                </div>

                <div class="flex-fill"></div>

                <!-- background-color: #3791A8; -->
                <div style="cursor: pointer; max-width: 150px"
                  class="w-100 rounded-3 d-flex align-items-center justify-content-end gap-1 py-1"
                  (click)="openCompareSiteHandler(compareResult)">
                  <p class="mb-0 text-dark fs-mb-16 fw-normal text-center">
                    Visit Dealer
                  </p>
                  <mat-icon class="text-dark large w-100" style="max-width: 16px"
                    data-nosnippet>chevron_right</mat-icon>
                </div>
              </div>
            </div>
            <!-- END MOBILE -->
          </div>
        </ng-container>
      </div>

      <!-- SEE MORE -->
      <div *ngIf="!seeAll && viewResults.length > visibleResults()"
        class="w-100 d-flex align-items-center justify-content-end gap-2">
        <div class="w-100" style="height: 1px; background-color: #969696"></div>
        <button
          class="border-0 mx-auto my-0 d-flex align-items-center justify-content-center gap-2 bg-white rounded-pill px-3 py-1 shadow-sm text-white"
          style="
            min-width: 135px;
            background-color: #4d818e !important;
            width: fit-content;
          " (click)="SelectAllHandler()">
          See More
        </button>
        <div class="w-100" style="height: 1px; background-color: #969696"></div>
      </div>
      <!-- SEE MORE END -->
    </div>
  </div>
</div>

<pla-footer-popout [opened]="false" [externalToggle]="mobileRowToggle" [actionText]="'Close'">
  <div *ngIf="selectedCompareResult" class="w-75 mx-auto">
    <div class="mx-auto text-center mt-3">
      <pla-plate [rawPlate]="registration" [white]="false" [mobileSmall]="false" [openModal]="false" [showPrice]="false"
        [useCustom]="true" [customClass]="'flat shaded'" [customSize]="9" [customFontSize]="40"></pla-plate>
    </div>
    <div class="my-2">
      <span class="d-block fw-bolder text-dark text-center mb-2 fs-mb-20">{{
        selectedCompareResult.viewName
        }}</span>
      <span class="d-block fw-normal text-dark text-center mb-3 fs-mb-18" *ngIf="selectedCompareResult.total > 0">
        {{
        selectedCompareResult.total
        | currency
        : "GBP"
        : "symbol"
        : (selectedCompareResult.total < 10000 ? "" : "1.0-0" ) }} </span>
          <span class="d-block fw-bolder text-dark text-end mb-0 fs-5" *ngIf="selectedCompareResult.total <= 0">
            Unavailable
          </span>
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <p class="my-0 fs-mb-18">Offers Finance</p>
      <mat-icon data-nosnippet class="{{ iconClasses }}"
        [style.color]="selectedCompareResult.finance ? '#44A43B' : '#C11414'">{{ selectedCompareResult.finance ? "done"
        : "close" }}</mat-icon>
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <p class="my-0 fs-mb-18">DVLA Recognised</p>
      <mat-icon data-nosnippet class="{{ iconClasses }}" [style.color]="true ? '#44A43B' : '#C11414'">{{ true ? "done" :
        "close" }}</mat-icon>
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <p class="my-0 fs-mb-18">Managed Transfer</p>
      <mat-icon data-nosnippet *ngIf="
          (compareFilterValues != undefined &&
            compareFilterValues.managedTransfer) ||
          selectedCompareResult.transferService == -1
        " class="{{ iconClasses }}" [style.color]="
          selectedCompareResult.transferService > -1 ? '#44A43B' : '#C11414'
        ">{{
        selectedCompareResult.transferService > -1
        ? compareFilterValues != undefined &&
        compareFilterValues.managedTransfer
        ? "done"
        : "close"
        : "close"
        }}</mat-icon>
      <small class="my-0 fs-mb-16" *ngIf="
          (compareFilterValues == undefined ||
            !compareFilterValues.managedTransfer) &&
          selectedCompareResult.transferService > 0
        ">{{
        selectedCompareResult.transferService / 100 | currency : "GBP"
        }}</small>
      <small class="my-0 fs-mb-16" *ngIf="
          (compareFilterValues == undefined ||
            !compareFilterValues.managedTransfer) &&
          selectedCompareResult.transferService == 0
        ">FREE</small>
    </div>

    <div class="d-flex align-items-center justify-content-between py-2">
      <p class="my-0 fs-mb-18">Standard Plates</p>
      <mat-icon data-nosnippet *ngIf="
          (compareFilterValues != undefined &&
            compareFilterValues.standardPlates) ||
          selectedCompareResult.physicalPlatePrice == -1
        " class="{{ iconClasses }}" [style.color]="
          selectedCompareResult.physicalPlatePrice > -1 ? '#44A43B' : '#C11414'
        ">{{
        selectedCompareResult.physicalPlatePrice > -1
        ? compareFilterValues != undefined &&
        compareFilterValues.standardPlates
        ? "done"
        : "close"
        : "close"
        }}</mat-icon>
      <small class="my-0 fs-mb-16" *ngIf="
          (compareFilterValues == undefined ||
            !compareFilterValues.standardPlates) &&
          selectedCompareResult.physicalPlatePrice > 0
        ">{{
        selectedCompareResult.physicalPlatePrice / 100 | currency : "GBP"
        }}</small>
      <small class="my-0 fs-mb-16" *ngIf="
          (compareFilterValues == undefined ||
            !compareFilterValues.standardPlates) &&
          selectedCompareResult.physicalPlatePrice == 0
        ">FREE</small>
    </div>
  </div>
</pla-footer-popout>

<!-- OVERLAY PANEL -->
<pla-footer-popout [opened]="false" [externalToggle]="mobileFilterToggle"
  (toggled)="compareFilters.emit(this.filterConfig)" [showBorder]="true" [actionText]="
    loading
      ? 'Loading...'
      : 'View ' +
        (viewResults !== undefined ? viewResults.length : '0') +
        ' Results'
  ">
  <div title class="d-flex align-items-center justify-content-start gap-2">
    <h4 class="lh-sm fs-md-20">FILTER</h4>
    <mat-icon data-nosnippet>tune</mat-icon>
  </div>
  <pla-side-bar-filters [showBorders]="true" [location]="'mobile-popup'"
    [filters]="compareFilters"></pla-side-bar-filters>
</pla-footer-popout>

<ng-template #skeletonRow>
  <div
    class="d-none d-md-flex justify-content-between align-items-stretch bg-white shadow-sm mb-3 mt-4 px-1 py-2 text-center skeleton opacity-50"
    style="border-radius: 20px">
    <!-- TITLE + TP -->
    <div
      class="border-end border-super-light flex-fill px-3 py-2 d-flex flex-column justify-content-center align-items-center w-100 overflow-hidden"
      style="min-width: 160px; max-width: 235px">
      <span class="price-size skeleton-item fs-5 mb-2 lh-sm d-block fw-normal">PLATEX</span>
      <div class="mx-auto w-100">
        <div style="max-width: 90px; height: 16px" class="mx-auto skeleton-item w-100"></div>
        <small style="max-width: 70px" class="d-block skeleton-item mx-auto text-truncate mt-1 lh-sm">0 reviews</small>
      </div>
    </div>

    <!-- FINANCE -->
    <div style="max-width: 90px"
      class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-center align-items-center w-100">
      <div class="icon-button skeleton-item"></div>
      <small class="w-100 d-block skeleton-item text-truncate mt-2">Offers Finance</small>
    </div>

    <!-- DVLA RECOGNISED -->
    <div style="max-width: 90px"
      class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-center align-items-center w-100">
      <div class="icon-button skeleton-item"></div>
      <small class="w-100 d-block skeleton-item text-truncate mt-2">DVLA Recognised</small>
    </div>

    <!-- Managed Transfer -->
    <div style="max-width: 90px"
      class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
      <div class="icon-button skeleton-item"></div>
      <small class="w-100 d-block skeleton-item text-truncate mt-2">Managed Transfer</small>
    </div>

    <!-- STANDARD PLATES -->
    <div style="max-width: 90px"
      class="border-end border-super-light flex-fill px-1 py-2 d-flex flex-column justify-content-between align-items-center w-100">
      <div class="icon-button skeleton-item"></div>
      <small class="w-100 d-block skeleton-item text-truncate mt-2">Standard Plates</small>
    </div>

    <div
      class="d-block d-xl-flex align-items-center justify-content-center flex-row flex-fill ps-2 w-100 py-2 px-2 gap-3">
      <div>
        <span class="price-size skeleton-item fs-md-16 fs-mb-16 lh-sm d-block fw-normal">£000,000</span>
      </div>
      <div style="max-width: 150px" class="skeleton-item w-100 rounded-pill py-2 mx-xl-0 mx-auto mt-2 mt-xl-0">
        <p class="mb-0 text-center">Visit Dealer</p>
      </div>
    </div>
  </div>

  <div class="skeleton opacity-50 d-block d-md-none bg-white shadow-sm mb-3 mt-2 p-3 text-center"
    style="border-radius: 20px">
    <button class="w-100 shadow-none d-flex border-0 align-items-center justify-content-between pb-1 bg-white px-0">
      <div class="icon-button skeleton-item flex-grow-0 flex-shrink-0 me-2" style="width: 24px; height: 24px"></div>
      <div>
        <span class="price-size skeleton-item fs-5 mb-0 d-block fw-normal">PLATEX</span>
      </div>

      <div class="flex-fill ps-2 w-100 text-end d-flex align-items-center justify-content-end gap-1">
        <span class="price-size skeleton-item fs-md-16 fs-mb-16 lh-sm d-block fw-normal">£000,000</span>
      </div>
    </button>

    <!-- BOTTOM -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="flex-grow-0 flex-shrink-0 me-2" style="width: 24px"></div>
      <div class="ms-0 me-auto">
        <div style="max-width: 90px; height: 16px" class="mx-auto skeleton-item w-100"></div>
        <small style="max-width: 70px" class="d-block skeleton-item mx-auto text-truncate mt-1 lh-sm">0 reviews</small>
      </div>

      <div class="flex-fill"></div>

      <span style="max-width: 150px" class="price-size skeleton-item fs-5 mb-0 d-block fw-normal">Visit Dealer</span>
    </div>
  </div>

  <div *ngIf="false"
    class="d-block d-md-none justify-content-between align-items-stretch bg-white shadow-sm mb-3 mt-4 px-1 py-2 text-center skeleton opacity-50">
    <h4 class="text-dark">Temp loader until new rows are designed</h4>
    <span class="price-size skeleton-item fs-md-16 fs-mb-16 lh-sm d-block fw-normal">£000,000</span>
    <div style="max-width: 150px" class="skeleton-item w-100 rounded-pill py-2 mx-xl-0 mx-auto mt-2 mt-xl-0">
      <p class="mb-0 text-center">Visit Dealer</p>
    </div>
  </div>
</ng-template>