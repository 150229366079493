<div class="component-space"></div>
<div class="component-container extended not-fixed {{ w_drop ? 'drop' : '' }}">
  <div class="content-container extended">
    <div class="header-container">
      <div class="header">
        <div class="logo">
          <a [routerLink]="'/landing'"
            ><pla-platex-direct [colour]="'white'"></pla-platex-direct
          ></a>
        </div>
        <div class="navigation">
          <div *ngIf="true" class="desktop flex-space">
            <!-- NON ICON NAVIGATION -->
            <ng-container *ngIf="false">
              <ng-container *ngFor="let navOption of navigation">
                <ng-container
                  *ngIf="navOption.showOnDesktop && !navOption.icon"
                >
                  <ng-container *ngIf="navOption.menu; else noMenu">
                    <a class="nav-item" [matMenuTriggerFor]="menu">
                      {{ navOption?.viewRoute }}
                    </a>
                    <!-- MAIN DROPDOWN MENU -->
                    <mat-menu #menu="matMenu">
                      <!-- SUB MENU -->
                      <ng-container *ngFor="let navMenuItem of navOption.menu">
                        <ng-container
                          *ngIf="navMenuItem.subMenu; else noSubMenu"
                        >
                          <button
                            mat-menu-item
                            [matMenuTriggerFor]="subMatMenu"
                          >
                            {{ navMenuItem?.viewRoute }}
                          </button>
                          <mat-menu #subMatMenu="matMenu">
                            <button
                              *ngFor="let navMenuSubItem of navMenuItem.subMenu"
                              mat-menu-item
                              (click)="navMenuSubItem.action()"
                            >
                              {{ navMenuSubItem?.viewRoute }}
                            </button>
                          </mat-menu>
                        </ng-container>

                        <!-- NO SUB MENU -->
                        <ng-template #noSubMenu>
                          <button mat-menu-item (click)="navMenuItem.action()">
                            {{ navMenuItem?.viewRoute }}
                          </button>
                        </ng-template>
                      </ng-container>
                    </mat-menu>
                  </ng-container>

                  <!-- NO MAIN DROPDOWN MENU -->
                  <ng-template #noMenu>
                    <ng-container *ngIf="navOption != null">
                      <a class="nav-item" [routerLink]="navOption.route">
                        {{ navOption?.viewRoute }}
                      </a>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>

            <div class="flex-space border-left"></div>

            <!-- SEARCH BAR -->
            <ng-container *ngIf="showDesktopSearchBar">
              <div class="desktop-search-container">
                <pla-option-search
                  [defaultSizing]="false"
                  [searchCriteria]="searchInput"
                  [endpoint]="'search-results'"
                  [inlineComponent]="true"
                  [deleteOnEnter]="true"
                ></pla-option-search>
              </div>
            </ng-container>

            <a
              class="nav-item"
              [routerLink]="'/dealer/home'"
              *ngIf="loadedAdmin()"
            >
              <mat-icon data-nosnippet class="text-white">storefront</mat-icon>
            </a>

            <pla-signed-up-content [requesting]="'favourite navigation'">
              <div lockedContent class="nav-item">
                <mat-icon data-nosnippet class="text-white"
                  >favorite_border</mat-icon
                >
              </div>
              <a
                unlockedContent
                class="nav-item"
                [routerLink]="'/account/favourites'"
              >
                <mat-icon data-nosnippet>favorite_border</mat-icon>
              </a>
            </pla-signed-up-content>

            <!-- ICON NAV OPTIONS -->
            <ng-container *ngFor="let navOption of navigation; let last = last">
              <ng-container *ngIf="navOption.showOnDesktop && navOption.icon">
                <ng-container *ngIf="navOption.menu; else noMenu">
                  <a class="nav-item withMenu" [matMenuTriggerFor]="menu">
                    <mat-icon
                      data-nosnippet
                      class="material-icons-outlined"
                      [matBadge]="notifcationCount"
                      [matBadgeOverlap]="true"
                      [matBadgeHidden]="notifcationCount == 0"
                      [matBadgeColor]="'warn'"
                      >{{ navOption.iconName }}</mat-icon
                    >
                  </a>
                  <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let navMenuItem of navOption.menu">
                      <button mat-menu-item (click)="navMenuItem.action()">
                        {{ navMenuItem?.viewRoute }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </ng-container>
                <ng-template #noMenu>
                  <a class="nav-item noMenu" [routerLink]="navOption.route">
                    <mat-icon
                      data-nosnippet
                      class="material-icons-outlined"
                      [matBadge]="notifcationCount"
                      [matBadgeOverlap]="true"
                      [matBadgeHidden]="notifcationCount == 0"
                      [matBadgeColor]="'warn'"
                      >{{ navOption.iconName }}</mat-icon
                    >
                  </a>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>

          <div class="mobile flex-space">
            <div class="flex-space"></div>
            <a
              class="nav-item"
              [routerLink]="'/dealer/home'"
              *ngIf="loadedAdmin()"
            >
              <mat-icon data-nosnippet class="text-white">storefront</mat-icon>
            </a>
            <pla-signed-up-content [requesting]="'favourite navigation'">
              <div lockedContent class="nav-item">
                <mat-icon data-nosnippet class="text-white"
                  >favorite_border</mat-icon
                >
              </div>
              <a
                unlockedContent
                class="nav-item"
                [routerLink]="'/account/favourites'"
              >
                <mat-icon data-nosnippet>favorite_border</mat-icon>
              </a>
            </pla-signed-up-content>
            <a
              *ngIf="showSearchBar"
              class="nav-item"
              (click)="toggleSearch($event, !showFloatingSearch)"
            >
              <mat-icon data-nosnippet>{{
                showFloatingSearch ? "search_off" : "search"
              }}</mat-icon>
            </a>
            <a class="nav-item" (click)="toggleNavigation()">
              <mat-icon
                data-nosnippet
                [matBadge]="notifcationCount"
                [matBadgeOverlap]="true"
                [matBadgeHidden]="notifcationCount == 0"
                [matBadgeColor]="'warn'"
                >menu</mat-icon
              >
            </a>
          </div>
        </div>
      </div>
      <div
        class="navigation mobile floating-navigation"
        *ngIf="showSearchBar"
        [ngClass]="{ open: showFloatingSearch }"
      >
        <div class="m-auto">
          <pla-option-search
            [defaultSizing]="false"
            [searchCriteria]="searchInput"
            [endpoint]="'search-results'"
            [inlineComponent]="true"
            [deleteOnEnter]="true"
            [smaller]="true"
            (searched)="toggleSearch(null, false)"
          ></pla-option-search>
        </div>
      </div>
    </div>
  </div>
</div>
